import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation,useParams, } from "react-router-dom";
import { reactFormatter } from "react-tabulator";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import {
  TabulatorTemplate,
  FullTabulatorTemplate,
} from "custom_components/templates/TabulatorTemplate.js";
import InvoiceApplicationCreate from "pages/finance/InvoiceApplicationCreate.js";
import InvoiceFilePreview from "pages/finance/InvoiceFilePreview.js";
import InvoiceApplicationInfo from "pages/finance/InvoiceApplicationInfo.js";

import {
  useFetchPost,
  useUserPermission,
  sessionSetLoading,
} from "../../custom_hooks/Actions.js";
import { flipTrueFalse } from "helper_functions/Helper.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseInvoiceApplication(data) {
  let dataForActive = [];
  let dataForInactive = [];
  try {
    for (let i = 0; i < data.length - 1; i++) {
      data[i].forEach((number, index, array) => {
        const obj = {
          no: index + 1,
          id: array[index]["InvoiceID"],
          supplierName : array[index]["SupplierName"],
          invoiceNumber: array[index]["InvoiceNumber"],
          invoiceDate : array[index]["InvoiceDate"],
          poNumber: array[index]["PoNumber"],
          projectCode: array[index]["ProjectCode"],
          doNumber: array[index]["DoNumber"],
          pendingDoNumber: array[index]["PendingDoNumber"],
          status:array[index]["Status"],
          approvalStatus:array[index]["ApprovalStatus"],
          viewFile: array[index]['FileStatus'],
          fileName: array[index]['MergedFilename'],
          // pendingCount: array[index]["PendingCount"],
          action: [],
        };
        if (i === 0) {
          if (data[i][0] !== 0) {
            dataForActive.push(obj);
          }
        } else {
          if (data[i][0] !== 0) {
            dataForInactive.push(obj);
          }
        }
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive, dataForInactive];
  return parsedData;
}



function InvoiceApplication({ permission }) {
   let { id } = useParams();
  let location = useLocation();
  const [refetch, setRefetch] = useState(0);

  // window.location.toString().split(path)[0] + path +
  const invoiceApplicationData = useFetchPost(
    domain + "/api/FinanceModule/InvoiceApplication.php?action",
    {},
    refetch
  );

  const parsedData = parseInvoiceApplication(invoiceApplicationData.data);
  const tabs = ["Active", "History"];
  const defaultTab = tabs[0];
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [redirect, setRedirect] = useState({ allow: false });
  const [HLID, setHLID] = useState("");
  const [status, setStatus] = useState("");

  function handleRefetch() {
    setRefetch(Math.random())
  }

  function handleUserPasswordRedirect(e) {
    e.preventDefault();
  }

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "Invoice ID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix:  'scan-invoice/' ,
      },
      //hacky way to make tabulator play well with react routers
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id ,type : 'id' }); //here we update state
      },
    },
    { title: "PO Number", field: "poNumber", headerFilter: "input" },
    { title: "Supplier Name", field: "supplierName", headerFilter: "input" },
    { title: "Invoice Number", field: "invoiceNumber", headerFilter: "input" },
    { title: "Invoice Date", field: "invoiceDate", headerFilter: "input" },
    { title: "Project Code", field: "projectCode", headerFilter: "input" },
    { title: "DO Number", field: "doNumber", headerFilter: "input" },
    // { title: "Attached File Status", field: "status", headerFilter: "input" },
    // { title: "Pending DO File", field: "pendingDoNumber", headerFilter: "input" },
    { title: "Approval Status", field: "approvalStatus", headerFilter: "input" },
    { title: "File Status", field: "status", headerFilter: "input" },
    {
      title: "View File",
      field: "viewFile",
      headerFilter: "input",
      formatter: (cell) => {
        const value = cell.getValue();
        if(value == 'View File'){
          const fileUrl = cell.getRow().getData().fileUrl;
          return `<a href="$" target="_blank" rel="noopener noreferrer"> View File </a>`;
        }else{
          return value;
        }
      },
      formatterParams: {
        labelField: "viewFile",
        // urlPrefix:   "/View",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id , type: 'image'}); //here we update state
      },
    }, 
  ];

  return redirect.allow ? (
    redirect.type == "image" ? (

      <Redirect push to={location.pathname+"/view/"+redirect?.id} />
      // <Redirect push to={`/${location.pathname}/view/${redirect.id}`}/>
    ) : (
      <Redirect push to={location.pathname+"/"+redirect?.id} />
      // <Redirect push to={`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/${redirect.id}`}/>
    )
   
  ) : invoiceApplicationData.status === "fetched" ? (
    
    <TabulatorTemplate
      moduleName={"InvoiceApplication"}
      columns={columns}
      moduleData={parsedData}
      tabs={tabs}
      disableWidget={permission.add ? flipTrueFalse(permission.add) : true}
      handleRefetch={handleRefetch}
    />
  ) : invoiceApplicationData.status === "fetching" ? (
    <div>Loading...</div>
  ) : (
    <div>Invoice Application is not available</div>
  );
}

function ApplicationContainer(props) {
  const permission = useUserPermission(35);

  return (
    <MainTemplate
      content={
        <Switch>
          {permission.add ? (
            <Route
              exact
              path="/:page/create"
              children={<InvoiceApplicationCreate />}
            />
          ) : null}
          {permission.view ? (
            <Route exact path="/:page/:id" children={<InvoiceApplicationInfo />} />
          ) : null}
          {permission.view ? (
            <Route exact path="/:page/view/:invoice" children={<InvoiceFilePreview />} />
          ) : null}
          {permission.view ? (
            <Route exact children={<InvoiceApplication permission={permission} />} />
          ) : null}
         
        </Switch>
      }
    />
  );
}

export default ApplicationContainer;
