import { useState } from "react";
import Tabs from '../../custom_components/Tabs.js';
import EmployeeDetails from 'pages/employee/EmployeeDetails.js';
import EmployeeCertificates from 'pages/employee/EmployeeCertificates.js';
import { WidgetBreadCrumbHeader } from '../../custom_components/Widgets.js'
import InvoiceApplicationDetails from "./InvoiceApplicationDetails.js";
import {
    useParams
} from "react-router-dom"
import PdfViewer from '../../custom_components/PdfViewer.js'
import { WidgetBackBtn } from '../../custom_components/Widgets.js'
import { useFetchPost } from '../../custom_hooks/Actions.js';

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function TabDisplay(props) {
    let activeTab = props.activeTab;
    let tabs = props.tabs;
    switch (activeTab) {
        case tabs[0]:
            return (<InvoiceApplicationDetails />);
        case tabs[1]:
            return (<InvoiceFilePreview />);
        default:
            return (null);
    }
}

function InvoiceApplicationInfo({ defaultTab }) {
    const tabs = ["Details", "File Attachment"];
    const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : tabs[0])
    return (
        <div>
            <WidgetBreadCrumbHeader prevNav="Invoice" curNav="Invoice Application Information" prevLink={"#"} curLink={"#"} />
            <br />
            <Tabs tabs={tabs} state={{ activeTab: [activeTab, setActiveTab] }} onClick={() => setActiveTab(defaultTab)} />
            <TabDisplay activeTab={activeTab} tabs={tabs} />
        </div>
    )
}


function InvoiceFilePreview(props) {
    let { id } = useParams();
    let fetchData = useFetchPost(
        // window.location.toString().split(path)[0] + path +
         domain + "/api/FinanceModule/InvoicePreview.php",{inId: id})
         function RenderViewer({ data }) {
            return (
                <div className="preview-container">
                    {data.map((item, index) => {
                        const fileExtension = item.split(".").pop().toLowerCase();  // Extract the file extension and convert to lowercase
        
                        return (
                            <div key={index} className="preview-item">
                                {fileExtension === "pdf" ? (
                                    <PdfViewer targetPath={item} />
                                ) : ["png", "jpg", "jpeg"].includes(fileExtension) ? (
                                    <img
                                        className=""
                                        src={item}
                                        alt={`preview-invoice-${index}`}
                                    />
                                ) : (
                                    <div>Preview is not available for file {item}</div>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        }

    return (
        <div className="mt-5 ">
            <div className="d-flex justify-content-center align-items-center">
                {
                    fetchData.status === "fetched" ?
                        <RenderViewer data={fetchData.data[1] ? fetchData.data[1] : null} />
                    : fetchData.status === "fetching" ?
                        <div>Loading...</div>
                    :
                        <div>Preview is not available for Cert ID {id}</div>
                }
            </div>
            
            <div className="form-button-grid">
                <WidgetBackBtn />
            </div>
        </div>
    )
}

export default InvoiceApplicationInfo;