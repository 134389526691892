import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Input,
  SelectInput,
  SelectInputWithGroup,
} from "../../custom_components/Forms.js";
import { CreateFormTemplateInvoice } from "../../custom_components/templates/FormTemplate";
import { useFetchPost, sessionSetLoading } from "../../custom_hooks/Actions.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function InvoiceApplicationCreate(props) {

  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [validated, setValidated] = useState(0);
  const [fileCheck, setFileCheck] = useState(0);

    const fields = [
        {
          name: "Po Number (eg : PO00000001)",
          inputname: "poNumber",
          type: "text",
          required: false,
        },
        {
          name: "Project Code (eg : GLP23001,GLP23002)",
          inputname: "projectCode",
          type: "text",
          required: false,
        },
        // {
        //   name: "Remarks",
        //   inputname: "remarks",
        //   type: "text",
        //   required: false,
        // },
        {
          name: "Urgent?",
          inputname: "urgent",
          type: "checkbox",
          required: false,
        },    
    ];

    const handleChangeInput = (e) => {

        if (e.target.type === "file") {

          let target = e.target;
          const filesArray = Array.from(target.files);
          filesArray.sort((a, b) => a.lastModified - b.lastModified);
          const fileDetails = filesArray.map((file) => ({
            url: URL.createObjectURL(file), 
            type: file.name.split(".").pop(), 
            data: file, 
          }));
        
          setInputData({
            ...inputData,
            [target.name]: fileDetails, 
          });
        
          setFileCheck(1);
        }
        else{
            if(typeof(e) == 'object'){
              setInputData({
                  ...inputData,
                  [e.target.name]: 
                      e.target.type ==="checkbox" ?
                          e.target.checked : e.target.value
              })
            }
          }
    }

    useEffect(() => {
      console.log(inputData);
      }, [inputData,fileCheck]);

    useEffect(() => {
        if (submitted === 1 ) {
          
            // let formData = JSON.stringify(inputData);

            let formData = new FormData();
            // formData.append("fileType", inputData["file-uploadType"]);
            // formData.append("file", inputData["file-uploadData"]);
            // Append file type array

            inputData["file-upload"].forEach((file, index) => {
              formData.append(`fileType[${index}]`, file.type);
              formData.append(`file[${index}]`, file.data);
            });
         
            formData.append(
              "formData",
              JSON.stringify({
                // remark : inputData['remarks']?inputData['remarks'] : null ,
                urgent : inputData['urgent']? inputData['urgent'] :false,
                poNumber : inputData['poNumber']?inputData['poNumber'] : null ,
                projectCode : inputData['projectCode']? inputData['projectCode'] :null,
              })
            );
            const url = domain + "/api/FinanceModule/InvoiceApplication.php?action=add"; 
          
             sessionSetLoading(true);
            let options = {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    body: formData,
                };
                
            fetch(url, options)
                .then((res) => res.json())
                .then((json) => {
                console.log(json);
                setSubmitted(0);
                setValidated(0);
                
                Swal.fire(
                  json[0] === 1 ? "Success!" : "Error!",
                  json[1],
                  json[0] === 1 ? "success" : "error"
                );
                 sessionSetLoading(false);
                })
                .catch((err) => {
                console.log(err);
                });
        }
    }, [submitted]);


  const fieldItems = fields.map((field, index) => (
    <div className="form-group row" key={field.inputname}>
      <label htmlFor={field.inputname} className="col-sm-3 ">
        {field.name}
      </label>
      <div className="col-sm-9">
        <Input type={field.type} field={field.inputname} value={inputData[field.inputname]} requiredOption={field.required} multiple={true} onChangeInput={handleChangeInput} dateDisabled={false}/>      
      </div>
    </div>
  ));

  function handleSubmit(e) {
    e.preventDefault();
    if(fileCheck === 1){
      setSubmitted(1);
    }
  }

  return (
    <CreateFormTemplateInvoice
      module="InvoiceApplication"
      handleSubmit={handleSubmit}
      handleChangeInput={handleChangeInput}
      fieldItems={fieldItems}
    />
  );
}

export default InvoiceApplicationCreate;
