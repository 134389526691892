// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import MainTemplate from "../../custom_components/templates/MainTemplate.js";
// import { useUserPermission } from "custom_hooks/Actions.js";

// function Module3() {
//   const [hasCompletedQuiz, setHasCompletedQuiz] = useState(false);
//   const permission = useUserPermission(13);
//   const [showFinalResult, setFinalresult] = useState(false);
//   const [score, setScore] = useState(0);
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [hasPass, setPass] = useState(false);
//   const [testResults, setTestResults] = useState([]);
//   const course_id = 3;
//   let finalscore = 1;
//   let fetchUrl = domain + "/api/EmployeeModule/";

//   if (showFinalResult) {
//     const data = JSON.stringify(course_id, score, hasPass);
//   }
//   const questions = [
//     {
//       test: "Performance value will be greater if Rubber Cone Fender size is?",
//       inputname: "Q11",
//       options: [
//         { id: 0, text: "a) Bigger", isCorrect: true },
//         { id: 1, text: "b) Smaller", isCorrect: false },
//       ],
//     },
//     {
//       test: "Fender front panel Polyethylene (PE) sliding plate is?",
//       inputname: "Q12",
//       options: [
//         { id: 0, text: "a) Fixed & not replaceable", isCorrect: false },
//         { id: 1, text: "b) Bolted & replaceable", isCorrect: true },
//       ],
//     },
//     {
//       test: "Which chain is used to support the weight of frontal and face panel?",
//       inputname: "Q13",
//       options: [
//         { id: 0, text: "a) Tension chain", isCorrect: false },
//         { id: 1, text: "b) Weight chain", isCorrect: true },
//         { id: 2, text: "c) Shear chain", isCorrect: false },
//       ],
//     },
//     {
//       test: "Which chain is to protect fender from damage while in shear deflection?",
//       inputname: "Q14",
//       options: [
//         { id: 0, text: "a) Tension chain", isCorrect: false },
//         { id: 1, text: "b) Weight chain", isCorrect: false },
//         { id: 2, text: "c) Shear chain", isCorrect: true },
//       ],
//     },
//     {
//       test: "When Install Fender Anchor Bolts at Jetty Concrete Deck, follow with which method?",
//       inputname: "Q15",
//       options: [
//         {
//           id: 0,
//           text: "a) In-situ cast with anchor and jetty concrete deck",
//           isCorrect: true,
//         },
//         {
//           id: 1,
//           text: "b) Drill to jetty concrete deck and install anchor bolt with epoxy.",
//           isCorrect: true,
//         },
//       ],
//     },
//     {
//       test: "Causes of damage to Jetty underdeck are?",
//       inputname: "Q16",
//       options: [
//         {
//           id: 0,
//           text: "a) Poor concrete quality & insufficient concrete cover",
//           isCorrect: true,
//         },
//         { id: 1, text: "b) Chloride penetration", isCorrect: true },
//         { id: 2, text: "c) Aging of concrete", isCorrect: true },
//       ],
//     },
//     {
//       test: "Chloride penetrated to Jetty Concrete Deck lead to?",
//       inputname: "Q17",
//       options: [
//         { id: 0, text: "a) Rebar corrosion", isCorrect: true },
//         { id: 1, text: "b) Spalling Concrete", isCorrect: true },
//         { id: 2, text: "c) Damaged Jetty underdeck", isCorrect: true },
//       ],
//     },
//     {
//       test: "Repairing of steel pile with coupler process, included with?",
//       inputname: "Q18",
//       options: [
//         {
//           id: 0,
//           text: "a) Blasting, power brushing to corroded steel pile",
//           isCorrect: true,
//         },
//         {
//           id: 1,
//           text: "b) Cover with steel pipe coupler and welding",
//           isCorrect: true,
//         },
//         { id: 2, text: "c) Painting", isCorrect: true },
//       ],
//     },
//     {
//       test: "Pile wrapping, pile jacketing & splash zone protection process included with?",
//       inputname: "Q19",
//       options: [
//         {
//           id: 0,
//           text: "a) Blasting, power brushing and cleaning of existing steel pile",
//           isCorrect: true,
//         },
//         {
//           id: 1,
//           text: "b) Apply primer and wrapping with tape",
//           isCorrect: true,
//         },
//         {
//           id: 2,
//           text: "c) Cover and jacket with heavy-duty pile wrapping material, tighten with SS bolt & nut",
//           isCorrect: true,
//         },
//       ],
//     },
//     {
//       test: "When calculating the length of pile wrapping, need to consider with?",
//       inputname: "Q20",
//       options: [
//         { id: 0, text: "a) MHWS (Mean High Water Spring)", isCorrect: true },
//         { id: 1, text: "b) LAT (Lowest Astronomical Tide)", isCorrect: true },
//       ],
//     },
//   ];
//   // helper
//   const optionClicked = (isCorrect) => {
//     if (isCorrect) {
//       setScore((prevScore) => prevScore + 1);
//     }
//     if (currentQuestion + 1 < questions.length) {
//       setCurrentQuestion(currentQuestion + 1);
//     } else {
//       setFinalresult(true);
//       // Calculate whether the user has passed the test based on the score
//       const hasPassed = score >= 6; // Adjust the passing score threshold as needed
//       console.log(hasPassed);
//       setPass(hasPassed); // Set the hasPass state
//       // Add the current test result to the testResults array
//       const result = {
//         course_id: course_id,
//         finalscore: score + 1,
//         hasPass: hasPassed ? "Pass" : "Fail",
//       };
//       setTestResults((prevResults) => [...prevResults, result]);
//     }
//   };
//   useEffect(() => {
//     // Define a function to handle the AJAX request
//     const fetchDataFromServer = async () => {
//       try {
//         if (testResults.length > 0) {
//           const response = await fetch(
//             domain + "/api/E-Learning/Elearning.php",
//             {
//               method: "POST",
//               headers: { "Content-Type": "application/json" },
//               body: JSON.stringify({ data: testResults }),
//             }
//           );

//           if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//           }

//           const data = await response.text();
//           // Handle the response from the server if needed
//           console.log(data);
//         }
//       } catch (error) {
//         // Handle any errors that occur during the AJAX request
//         console.error("Error:", error);
//       }
//     };

//     // Call the fetchDataFromServer function
//     fetchDataFromServer();
//   }, [testResults]);
//   return (
//     <MainTemplate
//       content={
//         <Router>
//           <div className="Module1test">
//             <h1>Module 1 & 2 Test</h1>
//             <h2>Current Score: {score} </h2>
//             {showFinalResult ? (
//               <div className="Final Result">
//                 <h1>Final Result</h1>
//                 <h2>
//                   {score} out of {questions.length} is correct-
//                   {(score / questions.length) * 100}%
//                 </h2>
//                 <h3 className="Passfail">
//                   {hasPass ? (
//                     <h3>You Have pass your test</h3>
//                   ) : (
//                     <h3>You have failed your test</h3>
//                   )}
//                 </h3>
//                 <button>Main page</button>
//               </div>
//             ) : (
//               <div className="Question-list">
//                 <h2 className="Question-text">
//                   Question {currentQuestion + 1} out of {questions.length}
//                 </h2>
//                 <h3 className="Question-text">
//                   {questions[currentQuestion].test}
//                 </h3>

//                 <ul>
//                   {questions[currentQuestion].options.map((option) => {
//                     return (
//                       <li
//                         onClick={() => optionClicked(option.isCorrect)}
//                         key={option.id}
//                       >
//                         {option.text}
//                       </li>
//                     );
//                   })}
//                 </ul>
//               </div>
//             )}
//           </div>

//           <Switch>
//             {permission.add && <Route path="/:subdom/:page/:module3" />}
//           </Switch>
//         </Router>
//       }
//     />
//   );
// }

// export default Module3;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route,Link ,useHistory } from "react-router-dom";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import { useFetchPost,useUserPermission } from "custom_hooks/Actions.js";
import ReactPlayer from "react-player";
import samplevideo from "../video/sample-video.mp4";


function Module3() {
  const path = process.env.REACT_APP_PATH;
  const domain = process.env.REACT_APP_DOMAIN
  let history = useHistory();
  const [hasCompletedQuiz, setHasCompletedQuiz] = useState(false);
  const permission = useUserPermission(13);
  const [showFinalResult, setFinalresult] = useState(false);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [hasPass, setPass] = useState(false);
  const [testResults, setTestResults] = useState([]);
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [validation, setValidation] = useState(true);
  const [videoPlay, setVideoPlay] = useState(true);
  const [tempResult, setTempResult] = useState({
    qus0 : '',
    qus1 : '',
    qus2 : '',
    qus3 : '',
    qus4 : '',
    qus5 : '',
    qus6 : '',
    qus7 : '',
    qus8 : '',
    qus9 : '',
   
  });
  const course_id = 3;
  const passCourseList = useFetchPost(
    domain + "/api/E-Learning/elearningUtility.php",
    { option: "option" }
  ).data;

  if(passCourseList.includes(3)){
    history.push(path + "/Module")
  }

  if (showFinalResult) {
    const data = JSON.stringify(course_id, score, hasPass);
  }
  const questions = [
        {
          test: "Q1 : Performance value will be greater if Rubber Cone Fender size is?",
          inputname: "Q11",
          options: [
            { id: 0, serial: "a)", text: "Bigger", isCorrect: true } ,
            { id: 1, serial: "b)", text: "Smaller", isCorrect: false },
          ],
        },
        {
          test: "Q2 : Fender front panel Polyethylene (PE) sliding plate is?",
          inputname: "Q12",
          options: [
            { id: 0, serial: "a)", text: "Fixed & not replaceable", isCorrect: false },
            { id: 1, serial: "b)", text: "Bolted & replaceable", isCorrect: true },
          ],
        },
        {
          test: "Q3 : Which chain is used to support the weight of frontal and face panel?",
          inputname: "Q13",
          options: [
            { id: 0, serial: "a)",text: "Tension chain", isCorrect: false },
            { id: 1, serial: "b)",text: "Weight chain", isCorrect: true },
            { id: 2, serial: "c)",text: "Shear chain", isCorrect: false },
          ],
        },
        {
          test: "Q4 : Which chain is to protect fender from damage while in shear deflection?",
          inputname: "Q14",
          options: [
            { id: 0, serial: "a)", text: "Tension chain", isCorrect: false },
            { id: 1, serial: "b)", text: "Weight chain", isCorrect: false },
            { id: 2, serial: "c)", text: "Shear chain", isCorrect: true },
          ],
        },
        {
          test: "Q5 : When Install Fender Anchor Bolts at Jetty Concrete Deck, follow with which method?",
          inputname: "Q15",
          options: [
            {
              id: 0,
              serial: "a)",
              text: "In-situ cast with anchor and jetty concrete deck",
              isCorrect: true,
            },
            {
              id: 1,
              serial: "b)",
              text: "Drill to jetty concrete deck and install anchor bolt with epoxy.",
              isCorrect: true,
            },
          ],
        },
        {
          test: "Q6 : Causes of damage to Jetty underdeck are?",
          inputname: "Q16",
          options: [
            {
              id: 0,
              serial: "a)",
              text: "Poor concrete quality & insufficient concrete cover",
              isCorrect: true,
            },
            { id: 1, serial: "b)", text: "Chloride penetration", isCorrect: true },
            { id: 2, serial: "c)", text: "Aging of concrete", isCorrect: true },
          ],
        },
        {
          test: "Q7 : Chloride penetrated to Jetty Concrete Deck lead to?",
          inputname: "Q17",
          options: [
            { id: 0, serial: "a)", text: "Rebar corrosion", isCorrect: true },
            { id: 1, serial: "b)", text: "Spalling Concrete", isCorrect: true },
            { id: 2, serial: "c)", text: "Damaged Jetty underdeck", isCorrect: true },
          ],
        },
        {
          test: "Q8 : Repairing of steel pile with coupler process, included with?",
          inputname: "Q18",
          options: [
            {
              id: 0,
              serial: "a)",
              text: "Blasting, power brushing to corroded steel pile",
              isCorrect: true,
            },
            {
              id: 1,
              serial: "b)",
              text: "Cover with steel pipe coupler and welding",
              isCorrect: true,
            },
            { id: 2,serial: "c)", text: "Painting", isCorrect: true },
          ],
        },
        {
          test: "Q9 : Pile wrapping, pile jacketing & splash zone protection process included with?",
          inputname: "Q19",
          options: [
            {
              id: 0,
              serial: "a)",
              text: "Blasting, power brushing and cleaning of existing steel pile",
              isCorrect: true,
            },
            {
              id: 1,
              serial: "b)",
              text: "Apply primer and wrapping with tape",
              isCorrect: true,
            },
            {
              id: 2,
              serial: "c)",
              text: "Cover and jacket with heavy-duty pile wrapping material, tighten with SS bolt & nut",
              isCorrect: true,
            },
          ],
        },
        {
          test: "Q10 : When calculating the length of pile wrapping, need to consider with?",
          inputname: "Q20",
          options: [
            { id: 0, serial: "a)", text: "MHWS (Mean High Water Spring)", isCorrect: true },
            { id: 1, serial: "b)", text: "LAT (Lowest Astronomical Tide)", isCorrect: true },
          ],
        },
      ];

  const videoNextClicked= () =>{
    
    setVideoPlay(false);
    setCount(count );

    if(currentQuestion <= questions.length ) {
      
      setCurrentQuestion(currentQuestion);
    }

    if(validation === false && tempResult['qus' + (currentQuestion)] === "" && (progress < (currentQuestion) * 10)){
      if (progress < 100) {
        setProgress(progress );
      } 
    }
  }

  const nextClicked= () =>{

      if(tempResult['qus' + (currentQuestion+1)]  === ""){
        setValidation(true);
      }else{
        setValidation(false);
      }
      
      if(count === 9){
        if (progress < 100) {
          setProgress(progress + 10);
        } 
     
        setFinalresult(true);
        // Calculate whether the user has passed the test based on the score
        const hasPassed = score >= 8; // Adjust the passing score threshold as needed
        console.log('hasPassed',hasPassed);
        setPass(hasPassed); // Set the hasPass state
        // Add the current test result to the testResults array
        const result = {
          course_id: course_id,
          finalscore: score,
          hasPass: hasPassed ? "Pass" : "Fail",
        };
        setTestResults((prevResults) => [...prevResults, result]);
      }else{
       
        setCount(count + 1);

        if(currentQuestion + 1 <= questions.length ) {
          
          setCurrentQuestion(currentQuestion + 1);
        }

        if(validation === false && tempResult['qus' + (currentQuestion+1)] === "" && (progress < (currentQuestion+1) * 10)){
          if (progress < 100) {
            setProgress(progress + 10);
          } 
        }
      }
  };

  const backClicked= () =>{
   
    if(count === 0){
      setVideoPlay(true);
      setCurrentQuestion(0);
    }else{
      setValidation(false);
      setCount(count - 1);
      if(currentQuestion - 1 <= questions.length) {
          setCurrentQuestion(currentQuestion - 1);
      }
    }
  
};

  const submitClicked = () =>{
    setFinalresult(true);
    // Calculate whether the user has passed the test based on the score
    const hasPassed = score >= 8; // Adjust the passing score threshold as needed
    setPass(hasPassed); // Set the hasPass state
    // Add the current test result to the testResults array
    const result = {
      course_id: course_id,
      finalscore: score + 1,
      hasPass: hasPassed ? "Pass" : "Fail",
    };
    setTestResults((prevResults) => [...prevResults, result]);
  };
 
  const optionClicked = (isCorrect,option_id,question_id) => {
    setValidation(false);
    setTempResult((prevState) => ({
      ...prevState,
      [question_id]: option_id,
    }));

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }
    
    // if (currentQuestion + 1 <= questions.length) {
    //   setCurrentQuestion(currentQuestion + 1);
    // }
  };
  useEffect(() => {
    
    // Define a function to handle the AJAX request
    const fetchDataFromServer = async () => {
      try {
        if (testResults.length > 0) {
         
          const response = await fetch(
            domain + "/api/E-Learning/Elearning.php?action=module",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ data: testResults }),
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.text();
          // Handle the response from the server if needed
          console.log(data);
        }
      } catch (error) {
        // Handle any errors that occur during the AJAX request
        console.error("Error:", error);
      }
    };

    // Call the fetchDataFromServer function
    fetchDataFromServer();
  }, [testResults]);
  // tempResult,currentQuestion,validation
  return (
    <MainTemplate
      content={
        <Switch>
          <div className="Module3test">
            <center><h6>Module 3 Test</h6></center>
            {showFinalResult ? (
              <div className="Final Result">
                <br/>
                <span className="align-right"> Question {currentQuestion + 1} out of {questions.length} </span>
             
                <br/>

                <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}>
                    {`${progress}% complete`}
                  </div>
                </div>
                <br/>

                <div class="card mt-4">
                 
                  <div class="card-body shadow" className={`${hasPass ? 'pass-card-border' : 'fail-card-border' }`}>
                     <h5> 
                      <center>
                        <img class="result" alt="result-picture" src={`${hasPass ? '/uploads/elearning/thumbs-up1.jpg' : '/uploads/elearning/thumbs-down.jpg' }`} /><br/>
                         <span>Here are your results: {score/10 * 100}% out of {questions.length/10 * 100}% </span><br/>
                         {/* <span class="score-text">Your Score : {score}</span> <br/>
                         <span class="score-text">Passing Score : {6}</span>  */}
                      </center>

                      
                      <br/>
                      { hasPass ? 
                      ( <div><center> Well Done!  <br/>  You have passed this test. </center></div>) 
                      : 
                      ( <div>
                          <center> 
                            Sorry!  <br/>  You have failed this test! You can retake the test when you are ready. <br/>
                            Go back to Main page to retake test.
                          </center>
                        </div>
                      ) }

                      <br/>
                      
                     </h5>

                     
                     
                     <center>
                    
                     <Link to="/module"> <button class="btn btn-primary">Main</button> </Link>
                     { hasPass ? <Link to="/module4"> <button  class="btn btn-info"> Next Test  </button> </Link> : '' }

                     </center> 
                  </div>

                </div>
                {/* <h1 >Final Result</h1>
                <h2>
                  {score} out of {questions.length} is correct-
                  {(score / questions.length) * 100}%
                </h2>
                <h3 className="Passfail">
                  {hasPass ? (
                    <h3>You Have pass your test</h3>
                  ) : (
                    <h3>You have failed your test</h3>
                  )}
                </h3> */}
               
                {/* {hasPass ?'': <button type="button" class="btn btn-primary float-right" onClick={() => submitClicked()}>SUBMIT</button> } */}
               
                {/* <button>Main page</button> */}
              </div>
            ) : (
              <div >
                <center>
                <br/>
                {videoPlay? ( 
                  <div>
                      <ReactPlayer  url={samplevideo} width="70%" height="50%" controls={true}/> 
                      <div class="row">
                         <div class="col-md-6"></div>
                         <div class="col-md-4" style={{ paddingRight : 0 }}>
                            <button type="button" class="btn btn-info float-right" onClick={() => videoNextClicked()} >NEXT</button> 
                         </div>
                         <div class="col-md-2"></div>
                      </div>
                  </div>
                ): 
                (
                 <div>
                    <span className="align-right"> Question {currentQuestion + 1} out of {questions.length} </span>
                    <br/>

                    <div className="progress-bar">
                      <div className="progress" style={{ width: `${progress}%` }}>
                        {`${progress}% complete`}
                      </div>
                    </div>

              
                    <br/><br/>
                    <div className="qus-btn">
                      <h5 class="align-left">  
                            { showFinalResult ?  '' : questions[currentQuestion].test }
                      </h5>
                    </div>
                    <hr/>
                    <br/>
                    <div class="row">
                        {questions[currentQuestion].options.map((option) => {
                        
                          return (
                          
                              <div class="col-md-6 mb-4">
                                
                                  <button  className={`button-option ${option.id === tempResult['qus'+currentQuestion] ? 'option-active' : '' }`} 
                                  // className="button-option"
                                      onClick={() => optionClicked(option.isCorrect,option.id,'qus'+currentQuestion)}
                                      key={option.id} 
                                  >
                                      <span class="align-left">{option.serial}</span>
                                      <span >{option.text}</span>
                                      
                                  </button>
                              </div>
                          );

                          return (
                            <row>
                              <col>
                                <button className="button-52"
                                    onClick={() => optionClicked(option.isCorrect)}
                                    key={option.id} 
                                >
                                    {option.text}
                                </button>
                              </col>
                            </row>
                          );
                        })}
                    </div>

                    <div>
                      <button type="button" class="btn btn-info float-right" onClick={() => nextClicked()} disabled={validation} >NEXT</button>
                      <button type="button" class="btn btn-secondary float-right" onClick={() => backClicked()}>BACK</button>
                      {/* { count != 0 ?  <button type="button" class="btn btn-secondary float-right" onClick={() => backClicked()}>BACK</button> : ''} */}
                    </div>
                 </div>
                )}
               </center>
              </div>
            )}
          </div>

          <Switch>
            {permission.add && <Route path="/:subdom/:page/:module3" />}
          </Switch>
        </Switch>
      }
    />
  );
}

export default Module3;

