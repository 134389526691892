import React, { useState, useEffect } from "react"
import {
    useParams
} from "react-router-dom"
import PdfViewer from '../../custom_components/PdfViewer.js'
import { WidgetBackBtn } from '../../custom_components/Widgets.js'
import { useFetchPost } from '../../custom_hooks/Actions.js';

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function DoFilePreview(props) {
    let { doid } = useParams()
    let fetchData = useFetchPost(
        // window.location.toString().split(path)[0] + path +
         domain + "/api/FinanceModule/DoPreview.php",{doId: doid})
         function RenderViewer({ data }) {
            return (
                <div className="preview-container">
                    {data.map((item, index) => {
                        const fileExtension = item.split(".").pop().toLowerCase();  // Extract the file extension and convert to lowercase
        
                        return (
                            <div key={index} className="preview-item">
                                {fileExtension === "pdf" ? (
                                    <PdfViewer targetPath={item} />
                                ) : ["png", "jpg", "jpeg"].includes(fileExtension) ? (
                                    <img
                                        className=""
                                        src={item}
                                        alt={`preview-do-${index}`}
                                    />
                                ) : (
                                    <div>Preview is not available for file {item}</div>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        }

    return (
        <div className="mt-5 ">
            <div className="d-flex justify-content-center align-items-center">
                {
                    fetchData.status === "fetched" ?
                        <RenderViewer data={fetchData.data[1] ? fetchData.data[1] : null} />
                    : fetchData.status === "fetching" ?
                        <div>Loading...</div>
                    :
                        <div>Preview is not available for Cert ID {doid}</div>
                }
            </div>
            
            <div className="form-button-grid">
                <WidgetBackBtn />
            </div>
        </div>
    )
}

export default DoFilePreview;