import {
  Link,
} from "react-router-dom"
import navHeader from './icons/WINS logo without name.png'
import profile_base from "icons/profile_base.jpg";
import { useEffect, useState } from "react";

var path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN


//Navbar component displays logo and contains minified navbar when app viewed on mobile
function Navbar({withLogout, handleLogout, fromApplicant}) {
  const [data, setData] = useState({});
  const [userPermission, setUserPermission] = useState();
  const [status, setStatus] = useState(false);
  const [hoverTab, setHoverTab] = useState()
  const [menuDisplayList, setMenuDisplayList] = useState({"Employee":[], "Course":[], "Recruitment":[], "Report":[], "Support":[], "HSE": [], "Finance": []})

  const menuObj = [
    {
      name: "Employee",
      path: "",
      submenu: [
        { name: "Employee List", path: "/employee", display: true },
        { name: "User Account", path: "/useraccount", display: true },
      ],
      display: true,
      mainTab: "Employee"
    },
    {
      name: "Training",
      path: "",
      submenu: [
        { name: "Application", path: "/application", display: false },
        { name: "Approval", path: "/approval", display: false },
        { name: "Recommend", path: "/recommend", display: false },
      ],
      display: false,
      mainTab: "Course"
    },
    {
      name: "E-learning",
      path: "",
      submenu: [
        { name: "Module", path: "/module", display: false },
        { name: "Score", path: "/video", display: false },
      ],
      display: false,
      mainTab: "Course"
    },
    {
      name: "HomeLeave",
      path: "",
      submenu: [
        { name: "Leave Application", path: "/homeleaveapplication", display: false },
        { name: "Leave Approval", path: "/homeleaveapproval", display: false },
      ],
      display: false,
      mainTab: "Employee"
    },
    {
      name: "Manpower",
      path: "",
      submenu: [
        {name: "Manpower Application", path:"/manpower", display: false},
        {name: "Manpower Approval", path:"/manpowerApproval", display: false},
      ], 
      display: false,
      mainTab: "Recruitment"
    },
    {
      name: "Interview",
      path: "",
      submenu: [
        {name: "Interview Application", path:"/interview", display: false},
        {name: "Interview Approval", path:"/interviewApproval", display: false},
        {name: "Interview Recommend", path:"/interviewRecommend", display: false},
      ], 
      display: false,
      mainTab: "Recruitment"
    },
    {
      name: "Appraisal",
      path: "",
      submenu: [
        {name: "Staff Appraisal", path:"/sa", display: false},
        {name: "Supervisor Appraisal", path:"/supera", display: false},
        {name: "Manager Approval", path:"/ma", display: false},
        {name: "Director Approval", path:"/da", display: false},
        {name: "Appraisal Setup", path:"/sasetup", display: false},
      ], 
      display: false,
      mainTab: "Employee"
    },
    { name: "Report", path: "/report", display: false, mainTab: "Report" },
    {
      name: "Support",
      path: "",
      submenu: [
        {name: "Helpdesk", path:"/hd", display: false},
        {name: "IT Requisition", path:"/itreq", display: false},
        {name: "Requisition Approval", path:"/itapp", display: false},
        {name: "Fixed Asset", path:"/fa", display: false},
      ], 
      display: false,
      mainTab: "Support"
    },
    {
      name: "HSE",
      path: "",
      submenu: [
        {name: "HSE", path:"/hazard", display: false},
      ], 
      display: false,
      mainTab: "HSE"
    },

    {
      name: "Invoice",
      path: "",
      submenu: [
        {name: "Invoice Scanning", path:"/scan-invoice", display: false},
        {name: "DO Scanning", path:"/scan-do", display: false},
        {name: "Manager Approval", path:"/invoice-ma-approval", display: false},
        {name: "Director Approval", path:"/invoice-da-approval", display: false},
      ],
      display: false,
      mainTab: "Finance"
    },
    // { name: "Sitemap", path: "/sitemap" },
    // { name: "Logout", path: "/logout" },
  ];

  const permReference = {
    //employee
    "Employee" : [],
    "Certificate": [],
    "UserAccount": [],
  
    //training
    "Application": [],
    "Feedback": [],
    "Evaluation": [],
    "null1": [],
    "ApplyCourse": [],
    "Recommend": [],
    "Approval": [],
    "Payment": [],
    "UserRightAccess": [],

    //report
    "null2": [],
    "Report": [],

    //HomeLeave
    "LeaveApplication": [],
    "LeaveApproval": [],

    //Manpower
    "ManpowerApplication" : [],
    "ManpowerApproval": [],
    "Manpower": [],

    //Interview
    "InterviewApplication": [],
    "InterviewApproval": [],
    "InterviewRecommend": [],
    "Interview": [],

    // Elearning
    "Module": [],
    "Score": [],

    //Appraisal
    "StaffAppraisal": [],
    "SupervisorAppraisal": [],
    "ManagerApproval": [],
    "DirectorApproval": [],
    "AppraisalSetup": [],

    // support
    "Helpdesk": [],
    "ITRequisition": [],
    "RequisitionApproval": [],
    "FixedAsset": [],

    // HSE
    "HSE": [],

    //Finance
    "InvoiceScanning" : [],
    "DOScanning" : [],
    "ManagerApproval" : [],
    "DirectorApproval" : [],
  };

  function userAccBoxAction(opt){
    var element = document.getElementsByClassName("userAccBox")[0]

    if(opt === "open"){
      // element.style.display = "block"
      element.style.minHeight = "80px"
    }else{
      // element.style.display = "none"
      element.style.minHeight = "0px"
    }
  }

  function processMenu(k){
    var element = document.getElementsByClassName("dropdownContent")[0]
    // element.style.display = "block";
    element.style.minHeight= "250px"
    element.style.padding = "2%";
    element.style.paddingLeft = "24%"

    var element2 = document.getElementsByClassName("dropdownBackground")[0]
    element2.style.display = "block";

    setHoverTab(k)
  }

  function closeMenu(){
    setHoverTab()
    var element = document.getElementsByClassName("dropdownContent")[0]
    element.style.minHeight= "0px"
    element.style.padding = "0%";

    // element.style.display = "none";

    var element2 = document.getElementsByClassName("dropdownBackground")[0]
    element2.style.display = "none";
  }   

  useEffect(() => {
    //Get permission
    const up = sessionStorage.getItem("userPermission")
    ? JSON.parse(sessionStorage.getItem("userPermission"))
    : null;
    if (up) {
      console.log("original up", up)
      console.log("userPermission", permReference)
      Object.keys(permReference).map((k,idx)=>{
        permReference[k] = [...up[idx]]
      })
      // permReference.forEach((element, index) => {
      //   permReference[index]["perm"] = up[index];
      // });
      setUserPermission(permReference);
      setStatus(true);
    }
    
    // Session Check
    fetch(domain + "/api/sessioncheck.php", {
      method: "GET",
      // mode: "same-origin",
      //credentials: "same-origin"
    })
      .then((res) => res.json())
      .then((json) => {
        setData({
          companyId: json[1],
          employeeCode: json[2],
          profilePicture: json[3],
          profilePictureExist: json[3].split("/")[4],
        });
      })
      .catch((err) => err);
      // Session Check

  }, []);


  // get menu display list after get User Permission
  useEffect(()=>{
    if(status === true){
      // get menu permission assign to dedicated array
      menuObj.map((menu, index) => {
        if(menu["name"] in userPermission && userPermission[menu["name"]][0] === 1){
          menu.display = true
        }
        // console.log(userPermission)    
        if(menu["submenu"]){
          menu["submenu"].map((d)=>{
            let tempName = d["name"].replace(" ", "")
            if(tempName in userPermission && userPermission[tempName][0] === 1){
              d["display"] = true
              // menu index list
              // 1 = training
              // 2 = elearning
              // 3 = homeleave
              // 6 = appraisal
              // 8 = support

              if((tempName === "Application" || tempName === "Approval" || tempName === "Recommend") && menuObj[1]["display"] === false){
                menuObj[1]["display"] = true
              }else if((tempName === "Module" || tempName === "Score")&& menuObj[2]["display"] === false){
                menuObj[2]["display"] = true
              }else if((tempName === "LeaveApplication" || tempName === "LeaveApproval") && menuObj[3]["display"] === false){
                menuObj[3]["display"] = true
              }else if((tempName === "StaffAppraisal" || tempName === "SupervisorAppraisal" || tempName === "ManagerApproval" || tempName === "DirectorApproval" || tempName === "AppraisalSetup") && menuObj[6]["display"] === false){
                menuObj[6]["display"] = true
              }else if((tempName==="Helpdesk" || tempName === "ItRequisition" || tempName === "RequisitionApproval" || tempName === "FixedAsset") && menuObj[8]["display"] === false ){
                menuObj[8]["display"] = true
              }else if((tempName==="InvoiceScanning" ||  tempName==="DOScanning" || tempName === "ManagerApproval" || tempName === "DirectorApproval" ) && menuObj[10]["display"] === false ){
                menuObj[10]["display"] = true
              }
              
            }
          })
        }
        
      })

      // Construct actual array
      menuObj.map((k)=>{
        if(k.display === true){
          let item = menuDisplayList[k.mainTab]
          let fullItem = menuDisplayList

          fullItem[k.mainTab] = [...item, {...k}]
          setMenuDisplayList(fullItem)
          // menuDisplayList[k.mainTab] = [...menuDisplayList[k.mainTab], {...k}]
        }
      })
      
    }
  }, [userPermission])

  // get menu display list after get User Permission

  // Self written function because bootstrap navbar collapse doesn't work
  // function doNavCollapse(element){
  //   var nav = document.getElementById("sidebarMenu")
  //   if (element.target.className.includes("collapsed")) {
  //     element.target.classList.remove("collapsed")
  //     element.target.setAttribute("aria-expanded", true)
  //     nav.classList.add("show")
  //   } else {
  //     element.target.classList.add("collapsed")
  //     element.target.setAttribute("aria-expanded", false)
  //     nav.classList.remove("show")
  //   }
  // }

  if(fromApplicant){
    return (
      <header className="navbar navbar-light bg-light navbar-color sticky-top flex-md-nowrap p-0 applicant_header">
        <Link className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" to={"/applicant"}>
          <img className="logo-fix" src={navHeader}  alt="GLEC"></img>
        </Link>
        {/* <button className="navbar-toggler position-absolute d-md-none collapsed" onClick={doNavCollapse} type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        {withLogout ? 
          <button className="btn btn-dark custom-btn" onClick={()=>handleLogout()}>Logout</button>
        :
          <></>
        }
      </header>
    )
  }else{
    return (
      <header className="sticky-top">
        <div className="newNavHeader">
          <div>
            <Link to={"/db"}>
              <img className="logo-fix" src={navHeader}  alt="WINS"></img>
            </Link>
          </div>
          <div className="menuDisplay">
            {Object.keys(menuDisplayList).map((k)=>{
              if(menuDisplayList[k].length !== 0){
                return (
                  <div className="dropdownContainer">
                    <p onMouseEnter={()=>processMenu(k)}>{k}</p>
                  </div>
                )
              }
            })}
          </div>
          <div className="dropdownContent"  onMouseLeave={()=>closeMenu()}>
            <div className="row">
              { hoverTab ?
                menuDisplayList[hoverTab].map((k)=>{
                  return(
                    <div className="fadeInText col-md-3">
                      <h5>{k.name}</h5>
                      <div>
                      {
                        k.submenu && k.submenu.length !== 0 ?
                          k.submenu.map((sk)=>{
                            let actualPath = sk.path
                            
                            if(sk.display !== false){
                              return(
                                <Link to={actualPath}><p>{sk.name}</p></Link>
                              )
                            }
                          })
                        : k.name === "Report" ?
                          <Link to={"/report"}><p>Report</p></Link>
                        :
                        <></>
                      }
                      </div>
                    </div>
                  )
                })
                :
                <></>
              }
            </div>
          </div>
          <div className="dropdownBackground">
          </div>
          <div>
            <span className="feather me-3">
              <img
                className="profile"
                onMouseEnter={()=>userAccBoxAction("open")}
                alt="profile-picture"
                src={
                  data.profilePictureExist
                    ? data.profilePicture
                    : profile_base
                }
              ></img>
            </span>
          </div>
          <div className="userAccBox" onMouseLeave={()=>userAccBoxAction("leave")}>
            <Link to={"/useraccount/" + data.employeeCode}><p>Account Details</p></Link>
            <Link to={"/logout"}><p>Logout</p></Link>
          </div>
        </div>
      </header>
    )
  }
}

export default Navbar