import { CreateFixedAssetForm } from "custom_components/templates/FormTemplate";
import { useFetchPost } from "custom_hooks/Actions";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function CreateFixedAsset({}){
    const history = useHistory();
    var [employeeList, setEmployeeList] = useState([""])
    var [employeeCategory, setEmployeeCategory] = useState([""])
    var [equipNo, setEquipNo] = useState()
    const [field, setField] = useState({
        cat: {displayText: "Category", dataType:"SelectOne", val:"", required: false, disable: false},
        subCat: {displayText: "Sub Category", dataType:"SelectOne", val:"", required: false, disable: false},
    })
    const date = new Date();

    let currentDay= String(date.getDate()).padStart(2, '0');

    let currentMonth = String(date.getMonth()+1).padStart(2,"0");

    let currentYear = date.getFullYear();

    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    const [issueOption, setIssueOption] = useState([""])

    var defaultHistoryUser = [
        {
            user: "HQ",
            issuer: "HQ",
            issueDate: currentDate,
            returnDate: currentDate,
        }
    ]

    const [subField, setSubField] = useState({
        equipmentNo: {displayText: "Equipment No", dataType:"text", val:"", required: true, disable: false},
        brand: {displayText: "Brand", dataType:"text", val:"", required: true, disable: false},
        seNo: {displayText: "Serial Number", dataType:"text", val:"", required: false, disable: false},
        model: {displayText: "Model", dataType:"text", val:"", required: true, disable: false},
        description: {displayText: "Description", dataType:"textbox", val:"", required: true, disable: false, ph: "Please fill up the device details \n(Example: any CPU, GPU, RAM, Mobile data, Storage, and other related information)"},
        purchaseDate: {displayText: "Purchase Date", dataType:"date", val:"", required: true, disable: false},
        purchaseValue: {displayText: "Purchase Value", dataType:"text", val:"", required: true, disable: false},
        warrantyDueDate: {displayText: "Warranty Due Date", dataType:"date", val:"", required: true, disable: false},
    })

    const [issueDetail, setIssueDetail] = useState({
        receiver: {displayText: "Receiver Name", dataType:"SelectGroup", val:"", required: true, disable: false},
        issuer: {displayText: "Issuer Name", dataType:"SelectOne", val:"", disable: true},
        issueDate: {displayText: "Issuer Date", dataType:"date", val:"", required: true, disable: false},
    })

    const categoryOption = {
        "Accessories": {
            "Adapter": {defaultStart: "GLEC-DAI002"},
            "Portable Drive": {defaultStart: "GLEC-USBP002"},
            "Dongle": {defaultStart: "GLEC-MR003"},
            "Fingerprint Scanner": {defaultStart: "NA"},
            "Portable DVD": {defaultStart: "NA"},
            "Memory Card": {defaultStart: "GLEC-TEMP001"},
        },
        "Camera": {
            "Conference Camera": {defaultStart: "GLEC-SPK001"},
            "Digital Camera": {defaultStart: "GLEC-CAM04"},
        },
        "Computer": {
            "Desktop": {defaultStart: "GLEC-DT015"},
            "Laptop": {defaultStart: "GLEC-NB072"},
            "Monitor": {defaultStart: "GLEC-DM27002"},
        },
        "Keyboard": {
            "Wireless": {defaultStart: "GLEC-WKB002"},
            "Non-wireless": {defaultStart: "GLEC-NWKB27001B"},
        },
        "Headset": {
            "USB": {defaultStart: "GLEC-H558"},
            "NON-USB": {defaultStart: "NA"},
        },
        "Mobile Phone": {
            "iPhone": {defaultStart: "GLEC-IPS052"},
        },
        "Data":{
            "Singtel": {defaultStart: "GLEC-ST023"},
            "M1": {defaultStart: "GLEC-MO003"},
            "Starhub": {defaultStart: "NA"},
            "TPG": {defaultStart: "GLEC-TPG011"},
            "Non-wireless": {defaultStart: "GLEC-NWD033"},
        },
        "Projector": {
            "Business Projector": {defaultStart: "NA"},
        },
        "Radio Talkie": {
            "IS": {defaultStart: "GLEC-ISRT014"},
            "NON-IS": {defaultStart: "GLEC-NISRT027"},
        },
        "Rack": {
            "Rack Enclosures": {defaultStart: "NA"},
        },
        "Server": {"NA": {defaultStart: "NA"}},
        "IP Phone": {"IP Phone": {defaultStart: "GLEC-IPP011"}},
        "TMS":{
            "Samsung": {defaultStart: "GLEC-TMSS018"},
            "Xiaomi": {defaultStart: "GLEC-TMSX015"},
            "Realme": {defaultStart: "GLEC-TMSR017"},
            "Fingerprint Scanner": {defaultStart: "GLEC-TMSFS022"},
        },
        "Router": {
            "Wired Router": {defaultStart: "GLEC-WR008"},
            "Wireless": {defaultStart: "GLEC-NWR009"},
        },
        "Walkie Talkie": {
            "IS": {defaultStart: "GLEC-ISWT062"},
            "NON-IS": {defaultStart: "GLEC-NISWT061"},
        },
        "Mouse": {
            "Wireless": {defaultStart: "GLEC-WM021"},
            "Non-wireless": {defaultStart: "GLEC-NWM072"},
        },
        "Webcam": {
            "Non-Wireless": {defaultStart: "NA"},
            "USB": {defaultStart: "GLEC-USBWC002"},
        }
    }
    
    function handleOptionsFromDb(empList, dept){
        empList.push(["HQ", "HQ"])
        setEmployeeList(empList)
        let catAry = []
        empList.map((dt)=>{
            if(!catAry.includes(dt[1]) && dt[1] !== "Driver"){
                catAry.push(dt[1])
            }
        })

        setEmployeeCategory(catAry)
    }

    function parseCustomData(dt){
        dt = dt.trim()
        dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
        dt = JSON.parse(dt)
       
       return dt
    }

    function getLatestEquipmentNo(){        
        fetch(
            domain + "/api/SupportModule/fixedAsset.php?action=getEquip&cat="+field["cat"].val+"&subCat="+field["subCat"].val,
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            if(json !== 0){
                let itemDetails = parseCustomData(json.itemDetail)
                setEquipNo(itemDetails.equipmentNo)
            }else{
                setEquipNo("")
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    function handleChanges(varName, value){
        if(varName in field){
            let item = field[varName]
    
            item.val = value
            
            setField({
                ...field,
                [varName]: {...item}
            })
            
            if(varName === "subCat"){
                getLatestEquipmentNo()
            }else{
                setField({
                    ...field,
                    ["subCat"]: {...field["subCat"], val: ""}
                })
            }
        }else if(varName in subField){
            let item = subField[varName]
            
            item.val = value
            
            setSubField({
                ...subField,
                [varName]: {...item}
            })

        }else{
            let item = issueDetail[varName]
    
            item.val = value
            
            setIssueDetail({
                ...issueDetail,
                [varName]: {...item}
            })
        }
    }

    useEffect(() => {
        fetch(
            domain + "/api/SupportModule/fixedAsset.php?action=getname",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleChanges("issuer", json["Name"])
        })
        .catch((err) => {
            console.log(err);
        });

        fetch(
            domain + "/api/SupportModule/fixedAssetCreateInfo.php",
        {
            method: "GET",
        }
        )
        .then((res) => res.json())
        .then((json) => {
            handleOptionsFromDb(json[3], json[1])
        })
        .catch((err) => {
            console.log(err);
        });   

        fetch(
            domain + "/api/SupportModule/fixedAsset.php?action=getPITEmpName",
            {
                method: "GET",
            }
            )
            .then((res) => res.json())
            .then((json) => {
                let tmp = []
                json.map((k)=>{
                    tmp.push(k.Name)
                })
                
                // console.log("tmp", tmp)
                setIssueOption(tmp)
                // handleOptionsFromDb(json[3], json[1])
            })
            .catch((err) => {
                console.log(err);
            });
        
    
      }, []);


    function handleSubmit(e){
        e.preventDefault()

        //validation check
        if(subField["brand"].val !== "" && subField["model"].val !== "" && subField["model"].val !== "" && subField["description"].val !== "" && subField["purchaseDate"].val !== "" && subField["purchaseValue"].val !== "" && subField["warrantyDueDate"].val !== "" && issueDetail["receiver"].val !== "" && issueDetail["issueDate"].val !== ""){
            let inputData = {}

            Object.keys(field).map((k)=>{
                if(field[k].val !== "" && field[k].val !== null){
                    inputData[k] = field[k].val 
                }else{
                    inputData[k] = "NA"
                }
            })

            //Combine data for subField
            var tmp = {}
            Object.keys(subField).map((k)=>{
                if(subField[k].val !== "" && subField[k].val !== null){
                    tmp[k] = subField[k].val
                }else{
                    tmp[k] = "NA"
                }
            })
            inputData["itemDetail"] = JSON.stringify(tmp)

            //Combine data for issueDetail
            var tmp = {}
            Object.keys(issueDetail).map((k)=>{
                if(issueDetail[k].val !== "" && issueDetail[k].val !== null){
                    tmp[k] = issueDetail[k].val
                }else{
                    tmp[k] = "NA"
                }
            })
            inputData["issueDetail"] = JSON.stringify(tmp)

            // let userList = Object.keys(defaultHistoryUser)
             
            defaultHistoryUser[defaultHistoryUser.length -1]["returnDate"] = issueDetail["issueDate"].val

            defaultHistoryUser = [
                ...defaultHistoryUser, 
                {
                    user: issueDetail["receiver"].val,
                    issuer: issueDetail["issuer"].val,
                    issueDate: issueDetail["issueDate"].val,
                    returnDate: ""
                }
            ]            

            inputData["historyUser"] = JSON.stringify(defaultHistoryUser)
            
            fetch(
                domain + "/api/SupportModule/fixedAsset.php?action=addFixedAsset",
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then((res) => {
                res.json()
            })
            .then((json) => {
                Swal.fire(
                    "Success!",
                    "Successfully submitted",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
            })
            .catch((err) => {
                console.log(err);
            });

            history.push("/fa")
        }else{
            Swal.fire(
                "Alert",
                "Please complete all required field",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }
    }


    return(
        <CreateFixedAssetForm
            module="Fixed Asset"
            from="create"
            field={field}
            subField={subField}
            issueDetail={issueDetail}
            handleChanges={handleChanges}
            handleSubmit={handleSubmit}
            categoryOption={categoryOption}
            optionGroups={employeeCategory}
            issueOption={issueOption}
            options={employeeList}
            equipNo={equipNo}
        />
    )
}