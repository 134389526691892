import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import { TabulatorTemplate } from "custom_components/templates/TabulatorTemplate.js";
import {
  sessionSetLoading,
  useFetchGet,
  useUserPermission,
} from "../../custom_hooks/Actions.js";
import { WidgetSubmitBtn } from "../../custom_components/Widgets.js";
import Swal from "sweetalert2";
import MergedFilePreview from "pages/finance/MergedFilePreview.js";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function parseInvoice(data) {
  let dataForActive = [];
  let dataForInactive = [];
  try {
    for (let i = 0; i < data.length; i++) {
      data[i].forEach((number, index, array) => {
        let tempItem = array[index];
        if (i === 0) {
          const obj = {
            no: index + 1,
            id: tempItem["InvoiceID"],
            action: "Please select an option",
            ApprovalStatus: tempItem["ApprovalStatus"],
            SupplierName: tempItem["SupplierName"],
            InvoiceNumber: tempItem["InvoiceNumber"],
            InvoiceDate: tempItem["InvoiceDate"],
            PoNumber: tempItem["PoNumber"],
            ProjectCode: tempItem["ProjectCode"],
            Status: tempItem["Status"],
            FileStatus: tempItem["FileStatus"],
            
          };
          if (data[i][0] !== 0) {
            dataForActive.push(obj);
          }
        } else {
          const obj = {
            no: index + 1,
            id: tempItem["InvoiceID"],
            action: "Please select an option",
            ApprovalStatus: tempItem["ApprovalStatus"],
            SupplierName: tempItem["SupplierName"],
            InvoiceNumber: tempItem["InvoiceNumber"],
            InvoiceDate: tempItem["InvoiceDate"],
            PoNumber: tempItem["PoNumber"],
            ProjectCode: tempItem["ProjectCode"],
            Status: tempItem["Status"],
            FileStatus: tempItem["FileStatus"],
          };
          if (data[i][0] !== 0) {
            dataForInactive.push(obj);
          }
        }
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive, dataForInactive];
  return parsedData;
}

function Approval({permission}) {
    let location = useLocation();
  const [refetch, setRefetch] = useState(0);
  // window.location.toString().split(path)[0] + path +

  const approvalData = useFetchGet(
    domain + "/api/FinanceModule/InvoiceApplicationManagerApproval.php",
    refetch
  );
  const parsedData = parseInvoice(approvalData.data);
  const tabs = ["Active", "History"];
  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [redirect, setRedirect] = useState({ allow: false });
  // const permission = useUserPermission(2)
//   const permission = useUserPermission(9);

  function handleRefetch() {
    setRefetch(Math.random());
  }

  useEffect(() => {
    if (submitted === true && permission.edit) {
      function prepareData(inputData) {
        let data = [];
        inputData.forEach((element) => {
          if (element.action !== "Please select an option") {
            data.push(element);
          }
        });
        return { inputData: data };
      }
      let data = prepareData(inputData);
      sessionSetLoading(true);
      // window.location.toString().split(path)[0] + path +
      fetch(
        domain + "/api/FinanceModule/InvoiceApplicationManagerApproval.php?action=update",
        {
          method: "POST",
          // mode: "same-origin",
          //credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json[0] === 1) {
            handleRefetch();
          }
          setSubmitted(false);
          Swal.fire(
            json[0] === 1 ? "Success!" : "Error!",
            json[1],
            json[0] === 1 ? "success" : "error"
          );
          sessionSetLoading();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [submitted]);

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "InvoiceID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix: "application/",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id, type: 'id' }); //here we update state
      },
    },
    {
      title: "Action",
      field: "action",
      name: "action",
      headerFilter: "input",
      editor: "select",
      editorParams: {
        values: ["Please select an option", "Approve", "Reject", "Withdraw"],
        defaultValue: "Please select an option",
        elementAttributes: {
          //style: "color:red;"
        },
      },
    },
  
    { title: "Supplier Name", field: "SupplierName", headerFilter: "input" },
    { title: "Invoice Number", field: "InvoiceNumber", headerFilter: "input" },
    { title: "Invoice Date", field: "InvoiceDate", headerFilter: "input" },
    { title: "Po Number", field: "PoNumber", headerFilter: "input" },
    { title: "Project Code", field: "ProjectCode", headerFilter: "input" },
    { title: "Approval Status", field: "ApprovalStatus", headerFilter: "input" },
    { title: "File Status", field: "Status", headerFilter: "input" },
    {
        title: "View File",
        field: "FileStatus",
        headerFilter: "input",
        formatter: (cell) => {
          const value = cell.getValue();
          if(value == 'View File'){
            const fileUrl = cell.getRow().getData().fileUrl;
            return `<a href="$" target="_blank" rel="noopener noreferrer"> View File </a>`;
          }else{
            return value;
          }
        },
        formatterParams: {
          labelField: "FileStatus",
          urlPrefix:   "/View",
        },
        //hacky way to make tabulator play well with react router
        cellClick: function (e, cell) {
          e.preventDefault();
          const rowInfo = cell._cell.row.data;
          setRedirect({ allow: true, id: rowInfo.id, type: 'image' }); //here we update state
        },
      },

  ];

  const altColumns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "InvoiceID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix: "application/",
      },
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id, type: 'id' }); //here we update state
      },
    },
    { title: "Supplier Name", field: "SupplierName", headerFilter: "input" },
    { title: "Invoice Number", field: "InvoiceNumber", headerFilter: "input" },
    { title: "Invoice Date", field: "InvoiceDate", headerFilter: "input" },
    { title: "Po Number", field: "PoNumber", headerFilter: "input" },
    { title: "Project Code", field: "ProjectCode", headerFilter: "input" },
    { title: "Approval Status", field: "ApprovalStatus", headerFilter: "input" },
    { title: "File Status", field: "Status", headerFilter: "input" },
    {
      title: "View File",
      field: "FileStatus",
      headerFilter: "input",
      formatter: (cell) => {
        const value = cell.getValue();
        if(value == 'View File'){
          const fileUrl = cell.getRow().getData().fileUrl;
          return `<a href="$" target="_blank" rel="noopener noreferrer"> View File </a>`;
        }else{
          return value;
        }
      },
      formatterParams: {
        labelField: "FileStatus",
        urlPrefix:   "/View",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id, type: 'image' }); //here we update state
      },
    },
  ];

  function handleSubmit(e) {
    e.preventDefault();
    if (permission.edit) {
      let emptyRemark = true;
      parsedData[0].forEach((element) => {
        if (element.action !== "Please select an option") {
          emptyRemark = false;
        }
      });
      if (emptyRemark === false) {
        setInputData(parsedData[0]);
        setSubmitted(true);
      } else {
        Swal.fire(
          "Warning!",
          "At least one row with action without remark, please input remark for row(s) with action",
          "warning"
        );
      }
    } else {
      Swal.fire("Warning!", "User not authorised to submit request", "warning");
    }
  }

//   if (redirect.allow) {
//     return (
//          <Redirect push to={location.pathname + "/" + redirect?.id} />
//     //   <Redirect from={path} push to={path + "/" + redirect?.id} />
//     );
//   } else {
//     return (
//       <MainTemplate

      
//         content={
//           approvalData.status === "fetched" ? (
//             <form onSubmit={handleSubmit}>
//               <TabulatorTemplate
//                 moduleName={"Invoice Application Approval"}
//                 columns={columns}
//                 altColumns={altColumns}
//                 moduleData={parsedData}
//                 tabs={tabs}
//                 disableWidget={true}
//                 moduleWidgets={
//                   permission.edit ? (
//                     <WidgetSubmitBtn text="Confirm Invoice approval action(s)" />
//                   ) : null
//                 }
//                 handleRefetch={handleRefetch}
//               />
             
//             </form>
//           ) : approvalData.status === "fetching" ? (
//             <div>Loading...</div>
//           ) : (
//             <div>Invoice Application Approval is not available</div>
//           )
//         }
//       />
//     );
//   }


return redirect.allow ? (
    // <Redirect
    //   push
    //   to={`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/${redirect.id}`} 
    // />
     redirect.type == "image" ? (
                <Redirect push to={location.pathname+"/view/"+redirect?.id} />
                // <Redirect push to={`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/view/${redirect.id}`}/>
              ) : (
                <Redirect push to={location.pathname+"/"+redirect?.id} />
                // <Redirect push to={`/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/${redirect.id}`}/>
              )
  ) : (
    approvalData.status === "fetched" ? (
        <form onSubmit={handleSubmit}>
        <TabulatorTemplate
            moduleName={"Invoice Application Approval"}
            columns={columns}
            altColumns={altColumns}
            moduleData={parsedData}
            tabs={tabs}
            disableWidget={true}
            moduleWidgets={
            permission.edit ? (
                <WidgetSubmitBtn text="Confirm Invoice approval action(s)" />
            ) : null
            }
            handleRefetch={handleRefetch}
        />
      </form>
    ) : approvalData.status === "fetching" ? (
      <div>Loading...</div>
    ) : (
      <div>Invoice Application Approval is not available</div>
    )
  );
    
}

function ApplicationContainer(props) {
    const permission = useUserPermission(37);
  
    return (
      <MainTemplate
        content={
          <Switch>
            {permission.view ? (
              <Route exact path="/:page/view/:id" children={<MergedFilePreview />} />
            ) : null}
            {permission.view ? (
              <Route exact children={<Approval permission={permission} />} />
            ) : null}
          </Switch>
        }
      />
    );
  }

export default ApplicationContainer;
