import React, { useState, useEffect } from "react"
import {
    useParams
} from "react-router-dom"
import PdfViewer from '../../custom_components/PdfViewer.js'
import { WidgetBackBtn } from '../../custom_components/Widgets.js'
import { useFetchPost } from '../../custom_hooks/Actions.js';

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function MergedFilePreview(props) {
    let { id } = useParams()
  
    let fetchData = useFetchPost(
        // window.location.toString().split(path)[0] + path +
         domain + "/api/FinanceModule/MergedFilePreview.php",{id: id})
         function RenderViewer({ data }) {
                // var img = data.split(".")[1][1];
            console.log("datas",data);
            switch(data.split(".")[1]) {
                case "pdf":
                case "PDF":
                    return <PdfViewer targetPath={data} />
                    break
                case "png":
                case "PNG":
                case "jpg":
                case "JPG":
                    return <img className="preview-img" src={data} alt="preview-cert" />
                    break
                default:
                    return <div>Preview is not available for Cert ID {id}</div>
                    break
            }
        }

    return (
        <div className="mt-5">
                    {
                        fetchData.status === "fetched" ?
                            <RenderViewer data={fetchData.data[1] ? fetchData.data[1] : null} />
                        : fetchData.status === "fetching" ?
                            <div>Loading...</div>
                        :
                            <div>Preview is not available for Cert ID {id}</div>
                    }
                    <div className="form-button-grid">
                        <WidgetBackBtn />
                    </div>
                </div>
    )
}

export default MergedFilePreview;