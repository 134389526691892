import { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  Input,
  SelectInput,
  SelectInputWithGroup,
} from "../../custom_components/Forms.js";
import {
  sessionSetLoading,
  useFetchGet,
  useFetchPost,
  useUserPermission,
} from "../../custom_hooks/Actions.js";
import {
  EditFormTemplate,
  EditFormTemplateDoApplication,
} from "../../custom_components/templates/FormTemplate";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

function DoApplicationDetails(props) {
  // window.location.toString().split(path)[0] + path +
  let { id } = useParams();
  const dataList = useFetchPost(
    domain + "/api/FinanceModule/DoUtility.php",
    { option: "option" }
  ).data;

  const [editMode, setEditMode] = useState(1);
  const [inputData, setInputData] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [validated, setValidated] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const defaultSelectInput = "Select an option";
  const fields = [
    {
      name: "Supplier Name",
      inputname: "SupplierName",
      type: "select",
      required: true,
      options: dataList[0],
      optionGroups: dataList[1],
    },
    {
      name: "Do Number",
      inputname: "DoNumber",
      type: "text",
      required: true,
    },
    {
      name: "Do Date",
      inputname: "DoDate",
      type: "date",
      required: true,
    },
    {
      name: "PO Number",
      inputname: "PoNumber",
      type: "text",
      required: true,
    },
    {
      name: "Project Code",
      inputname: "ProjectCode",
      type: "text",
      required: true,
    },
    {
      name: "Status",
      inputname: "Status",
      type: "select",
      required: true,
      options: ["Active", "Inactive"],
    },
    // {
    //   name: "Update Invoice File that related to this Do ?",
    //   inputname: "changeInv",
    //   type: "checkbox",
    //   required: false,
    // },  
  ];
  const notRequiredInputData = [];
  const permission = useUserPermission(36);

  const handleChangeInput = (e, date) => {
    if (typeof e == "object") {

      setInputData({
        ...inputData,
        [e.target.name]: 
            e.target.type ==="checkbox" ?
                e.target.checked : e.target.value
    })
     
      // setInputData({
      //   ...inputData,
      //   [e.target.name]: e.target.value,
      // });
    
    } else {
      if (typeof e != "object") {
        setInputData({
          ...inputData,
          [e]: date,
        });
      }
    }
  };

  function createInputObj(json) {
   
    let a = {};
    for (let i = 0; i < fields.length; i++) {
      let inputName = fields[i].inputname;
      if (fields[i].type === "date" && json[inputName]) {
        let splitDate = json[inputName].split("-");
        let date = new Date(
          splitDate[2],
          parseInt(splitDate[1]) - 1,
          parseInt(splitDate[0]) + 1
        );
        a[inputName] = date.toISOString().split("T")[0];
      } else {
        a[inputName] = json[inputName] ? json[inputName] : "";
      }
    }
    return a;
  }

  const handleInputChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });

   
  };

  useEffect(() => {
    if (submitted === 0) {
      // window.location.toString().split(path)[0] + path +
      fetch(
        domain + "/api/FinanceModule/DoApplication.php?action=search",
        {
          method: "POST",
          // mode: "same-origin",
          //credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            DOID: id,
          }),
        }
      )
        .then((res) => res.json())
        .then((json) => {
          setInputData(createInputObj(json));
        })
        .catch((err) => {
        });
    }
  }, []);



  useEffect(() => {
    if (submitted === 1 && validated === 1 && permission.edit) {

      let formData = new FormData();
      
      formData.append(
        "inputData",
        JSON.stringify({
          DOID: id,
          PoNumber: inputData["PoNumber"],
          ProjectCode: inputData["ProjectCode"],
          Status: inputData["Status"],
          // ChangeDoFile : inputData["changeInv"],
          DoNumber : inputData["DoNumber"]
        })
      );

      sessionSetLoading(true);
  
      // window.location.toString().split(path)[0] + path +
      fetch(
        domain + "/api/FinanceModule/DoApplication.php?action=update",
        {
          method: "POST",
          // mode: "same-origin",
          //credentials: "same-origin",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            DOID: id,
            PoNumber: inputData["PoNumber"],
            ProjectCode: inputData["ProjectCode"],
            Status: inputData["Status"],
            // ChangeInvFile : inputData["changeInv"],
            DoNumber : inputData["DoNumber"]
          }),
          // body: formData,
        }
      )
        .then((res) => res.json())
        .then((json) => {
          Swal.fire(
            json[0] === 1 ? "Success!" : "Error!",
            json[1],
            json[0] === 1 ? "success" : "error"
          );
          if (json[0] === 1) {
            setRedirect(true);
          } else {
            setSubmitted(0);
            setValidated(0);
          }
          sessionSetLoading();
        })
        .catch((err) => {
        });
    }
    return null;
  }, [submitted, validated]);

  const dateNow = new Date();
  const dateMin =
    dateNow.getFullYear() +
    "-" +
    (dateNow.getMonth() + 1) +
    "-" +
    dateNow.getDate();
  let formClassName = "form-group";
  let formDisplayWide = formClassName + " grid-wide";
  let formDisplayNone = formClassName + " d-none";
  const fieldItems = fields.map((field, index) => (
    <div
      className={formDisplayWide}
      key={field.inputname}
    >
      <label htmlFor={field.inputname} >
        {field.name}
      </label>
      <div >
        {field.type === "select" && field.optionGroups ? (
          <SelectInputWithGroup
            field={field.inputname}
            options={field.options}
            value={inputData[field.inputname]}
            optionGroups={field.optionGroups}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        ) : index == 0 ? (
          <SelectInput
            field={field.inputname}
            value={inputData[field.inputname]}
            options={field.options}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={1}
          />
        ) : index == 5  ? (
          <SelectInput
            field={field.inputname}
            value={inputData[field.inputname]}
            options={field.options}
            defaultOption={defaultSelectInput}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        ) : index == 1 ||index == 2  ? (
          <Input
            field={field.inputname}
            type={field.type}
            value={inputData[field.inputname]}
            dataList={field.datalist ? field.datalist : null}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={1}
          />
        ) :  (
          <Input
            field={field.inputname}
            type={field.type}
            value={inputData[field.inputname]}
            dataList={field.datalist ? field.datalist : null}
            requiredOption={field.required}
            onChangeInput={handleChangeInput}
            disabled={editMode}
          />
        ) }
      </div>
    </div>
  ));

  function handleSubmit(e) {
   
    e.preventDefault();

    for (let inputName in inputData) {
      let isAnExcept = 0;
      for (let exceptNum in notRequiredInputData) {
        if (inputName === notRequiredInputData[exceptNum]) {
          isAnExcept = 1;
        }
      }
      if (isAnExcept === 0) {
        if (
          inputData[inputName] === defaultSelectInput ||
          inputData[inputName] === "" ||
          inputData[inputName] === null ||
          inputData[inputName] === "undefined"
        ) {
          setValidated(0);
          setSubmitted(0);
        } else {
          break;
        }
      } else {
        break;
      }
    }
    setValidated(1);
    setSubmitted(1);
  }

  if (redirect) {
    return <Redirect to={path + "/scan-invoice"} />;
  } else {
 

    return (
      <div>
        {
          <EditFormTemplateDoApplication
            module="DoApplication"
            handleSubmit={handleSubmit}
            disabled={editMode}
            setEditMode={setEditMode}
            fieldItems={fieldItems}
            editAccess={permission.edit}
            // dataList={dataList[0]}
            handleChangeInput={handleChangeInput}
          />
        }
      </div>
    );
  }
}

export default DoApplicationDetails;
