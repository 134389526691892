import { ImportFileForm, ManpowerCheckbox,ImportInvoiceForm,ImportDoForm} from '../../custom_components/Forms.js'
import { WidgetCreateFormBtnGrp, WidgetEditFormBtnGrp, WidgetTrainingFormBtnGrp, WidgetBreadCrumbHeader, WidgetBackBtn,ExcelDownloadBtn, WidgetCreateFormBtnGrpC } from '../../custom_components/Widgets.js'
import "../../css/Forms.css"
import React from 'react'
import { Input, SelectInput, SelectInputWithGroup } from '../../custom_components/Forms.js'
import { Link } from 'react-router-dom'
import { ImportImageForm } from '../../custom_components/Forms.js'

const path = process.env.REACT_APP_PATH

function FormTemplate(props) {
}

//FixedAsset Form
function CreateFixedAssetForm({module, from, field, subField, issueDetail,  handleChanges, handleSubmit, categoryOption, optionGroups, options, equipNo, issueOption, curAction, handleCurAction, editable, historyUser, handleDelete}){
  var curNav = ""
  var disableOption = false
  if(from === "create"){
    curNav = "Add"
  }else{
    curNav = "Details"
  }
  
  if(from === "edit" && curAction !== "submit"){
    disableOption = true
  }

  return(
    <div>
      <form onSubmit={handleSubmit}>
        {
            module ? 
              <React.Fragment>
                <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
                <br />
              </React.Fragment>
            :
              null
          }
          <br />

          {/* Field */}
          {Object.keys(field).map((k)=>{
            if(k !== "assetId"){
              return(
                <>
                  {field[k].dataType === "SelectOne" ?
                    k === "cat" ? //category 
                      <div className='row staffOtherSDiv'>
                        <label className='col-sm-4'>{field[k].displayText}</label>
                        <div className='col-sm-8'>
                        <SelectInput options={Object.keys(categoryOption)} defaultOption="Select an option" value={field[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />                  
                        </div>
                      </div>
                    : field["cat"].val !== null && field["cat"].val !== "" ?  // sub category
                      <div className='row staffOtherSDiv'>
                        <label className='col-sm-4'>{field[k].displayText}</label>
                        <div className='col-sm-8'>
                        <SelectInput options={Object.keys(categoryOption[field["cat"].val])} defaultOption="Select an option" value={field[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} /> 
                        </div>
                      </div>
                      :
                        <></>
                    : 
                      <></>
                    }
                </>
              )
            }
          })}

          <hr />

          {/* innerDetails */}
          { field["cat"].val !== null && field["cat"].val !== "" && field["subCat"].val !== null && field["subCat"].val !== "" ?
          <>
            {Object.keys(subField).map((k)=>{
              if(k !== "condition"){
                return(
                <div className='row staffOtherSDiv'>
                  <label className='col-sm-4'>{subField[k].displayText} {k === "equipmentNo" ? <small><br /><i><b>Previous: ({equipNo !== "" ? equipNo : categoryOption[field["cat"].val][field["subCat"].val]["defaultStart"]})</b></i></small>: <></>}</label>
                  <div className='col-sm-8'>
                    {subField[k].dataType === "text" ?
                      <input type={subField[k].dataType} required={subField[k].required} className="form-control" name={k} value={subField[k].val} disabled={disableOption} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                    : subField[k].dataType === "textbox"?
                      <textarea rows={6} required={subField[k].required} disabled={disableOption} placeholder={subField[k].ph ?subField[k].ph : "123" } className="form-control" value={subField[k].val} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                    : subField[k].dataType === "date"?
                      <Input type="date" onChangeInput={handleChanges} value={subField[k].val} field={k} disabled={disableOption} requiredOption={subField[k].require} />
                    :
                    <></>
                    }
                  </div>
                </div>
                )
              }
            })}
            <hr />
            {/* Issue Details */}
            {Object.keys(issueDetail).map((k)=>{
              return(
                <div className='row staffOtherSDiv'>
                  <label className='col-sm-4'>{issueDetail[k].displayText}</label>
                  <div className='col-sm-8'>
                    {issueDetail[k].dataType === "text" ?
                      <input type={issueDetail[k].dataType} required={issueDetail[k].required} className="form-control" name={k} value={issueDetail[k].val} disabled={disableOption} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                    : issueDetail[k].dataType === "SelectGroup"?
                      <SelectInputWithGroup
                        field={k}
                        value={issueDetail[k].val}
                        options={options}
                        optionGroups={optionGroups}
                        defaultOption="Select an option"
                        onChangeInput={handleChanges}
                        fromApplicant={true}
                        requiredOption={issueDetail[k].required}
                        disabled={disableOption}
                      />
                    : issueDetail[k].dataType === "SelectOne"?
                      issueOption.includes(issueDetail[k].val) ?
                        <SelectInput options={issueOption} defaultOption="Select an option" value={issueDetail[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={issueDetail[k].required} onChangeInput={handleChanges} />
                      :
                        <input type="text" required={issueDetail[k].required} className="form-control" name={k} value={issueDetail[k].val} disabled={disableOption} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                    : issueDetail[k].dataType === "date"?
                      <Input type="date" onChangeInput={handleChanges} value={issueDetail[k].val} field={k} requiredOption={issueDetail[k].require} disabled={disableOption} />
                    :
                      <></>
                    }
                  </div>
                </div>
              )
            })}

            <hr />
            
            {/* User History */}
            {
              from === "edit" ?
                <div className='apDiv'><br />
                  <h6>
                    <u>User History</u>
                  </h6><br />
                  <table className='aTable'>
                    <tr>
                      <th>Past User</th>
                      <th>Issuer</th>
                      <th>Issue Date</th>
                      <th>Return Date</th>
                      {curAction === "submit" ? 
                        <th>Action</th>
                      :
                        <></>                      
                      }
                    </tr>
                  {
                    Object.keys(historyUser).map((k)=>{
                      return(
                        <tr>
                          <td>{historyUser[k]["user"]}</td>
                          <td>{historyUser[k]["issuer"]}</td>
                          <td>{historyUser[k]["issueDate"]}</td>
                          <td>{historyUser[k]["returnDate"]}</td>
                          {curAction === "submit" ? 
                            <td>
                              <svg onClick={()=>handleDelete(k)} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z"/> </g> 
                              </svg>
                            </td>
                          :
                            <></>                      
                          }
                        </tr>
                      )
                    })
                  }
                  </table>

                </div>
                
                              
              :
                <></>
            }

            {
              from === "create" ? 
                <WidgetCreateFormBtnGrpC action = "submit" />
              :
                <WidgetCreateFormBtnGrpC handleCurAction={handleCurAction} editable={editable} permission={{edit:1}} action={curAction} /> 
            }
          </>
            :
            <></>
          }
          
      </form>
    </div>
  )
}


//Requisition form
function CreateRequisitionForm({module, from, curNav="Add", field, hardwareOption, approvalOption, handleChanges, handleSubmit, userCode, userRole, handleEditApproval, action, editable, userDept, requestField}){
  if(from === "create"){
    curNav = "Add"
  }else{
    curNav = "Details"
  }
  
  var disabling = true
  if(from === "approval" && action === "submit"){
    disabling = false
  }

  return(
    <div>
      <form onSubmit={handleSubmit}>
        {
          module ? 
            <React.Fragment>
              <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
              <br />
            </React.Fragment>
          :
            null
        }
        {from === "create" ? 
          <div>
          <div className='row staffOtherSDiv'>
            <label className='col-sm-4'>{field["requestorName"].displayText}</label>
            <div className='col-sm-8'>
                <input type={field["requestorName"].dataType} required={field["requestorName"].required} className="form-control" name={"requestorName"} value={field["requestorName"].val} disabled={field["requestorName"].disable} onChange={(t)=>{handleChanges("requestorName", t.target.value)}} />
            </div>
          </div>
          <div className="contentMainDiv">
            {Object.keys(field).map((k)=>{
              if(k === "hardwareRequest" || k === "softwareRequest" || k === "hardwareOther"){
                if(k === "hardwareRequest"){
                  // Hardware Part
                  return(
                    <div className='hardSoftDiv'>
                      <div style={{ display: 'flex'}}>
                        <ManpowerCheckbox type="checkbox" field={"hardware"} value={"hardware"} checked={requestField.includes("hardware") ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                        <div>Hardware Request</div>
                      </div>
                      <div className='row staffOtherSDiv'>
                        <label className='col-sm-4'>{field[k].displayText}</label>
                        <div className='col-sm-8'>
                          <SelectInput options={hardwareOption} defaultOption="Select an option" value={field[k].val} disabled={requestField.includes("hardware")? false: true} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                        </div>
                      </div>
                      {field["hardwareRequest"].val === "Others" ? 
                        <div className='row staffOtherSDiv'>
                          <label className='col-sm-4'>{field["hardwareOther"].displayText}</label>
                          <div className='col-sm-8'>
                              <input type={field["hardwareOther"].dataType} required={field["hardwareOther"].required} className="form-control" name={"hardwareOther"} value={field["hardwareOther"].val} disabled={field["hardwareOther"].disable} onChange={(t)=>{handleChanges("hardwareOther", t.target.value)}} />
                          </div>
                      </div>
                      :
                      <></>
                      }
                    </div>
                  )
                }else if(k === "softwareRequest"){
                  // Software Part
                  return(
                    <div className='hardSoftDiv'>
                      <div style={{ display: 'flex'}}>
                        <ManpowerCheckbox type="checkbox" field={"software"} value={"software"} checked={requestField.includes("software") ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                        <div>Software Request</div>
                      </div>
                      <div className='row softwareBox'>
                        {/* Microsoft office checkbox */}
                        <div className='col-md-6' style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"mo"} checked={field[k].val["mo"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          <div>MS Office</div>
                        </div>
                        {/* Antivirus checkbox */}
                        <div className='col-md-6' style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"anti"} checked={field[k].val["anti"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          <div>Antivirus</div>
                        </div>
                        {/* Adobe Reader checkbox */}
                        <div className='col-md-6' style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"adobe"} checked={field[k].val["adobe"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          <div>Adobe Reader</div>
                        </div>
                        {/* Sharepoint Checkbox */}
                        <div className='col-md-6' style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"sp"} checked={field[k].val["sp"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          <div>Sharepoint</div>
                        </div>
                        {/* Teams Checkbox */}
                        <div className='col-md-6' style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"team"} checked={field[k].val["team"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          <div>Teams</div>
                        </div>
                        {/* Outlook Checkbox */}
                        <div className='col-md-6' style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"outlook"} checked={field[k].val["outlook"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          <div>Outlook</div>
                        </div>
                        {/* Others text */}
                        <input type="text" required={false} className="form-control" name={"softwareOther"} value={field[k].val["other"]} disabled={false} onChange={(t)=>{handleChanges("softwareSelect", t.target.value)}} placeholder='Other software (Optional) ' />
                        
                      </div>
                    </div>
                  )
                }
              }
            })}
          </div>

          <div className='row staffOtherSDiv'>
            <label className='col-sm-4'>{field["requestReason"].displayText}</label>
            <div className='col-sm-8'>
              <textarea rows={6} required={field["requestReason"].required} disabled={field["requestReason"].disable} className="form-control" value={field["requestReason"].val} onChange={(t)=>{handleChanges("requestReason", t.target.value)}} />
            </div>
          </div>
          
        </div>
          ///View details section
          : from === "view" || from === "approval" ?
            //show for approval
            <div>
            <div className='row staffOtherSDiv'>
              <label className='col-sm-4'>{field["requestorName"].displayText}</label>
              <div className='col-sm-8'>
                  <input type={field["requestorName"].dataType} required={field["requestorName"].required} className="form-control" name={"requestorName"} value={field["requestorName"].val} disabled={true} onChange={(t)=>{handleChanges("requestorName", t.target.value)}} />
              </div>
            </div>
            <div className="contentMainDiv">
              {Object.keys(field).map((k)=>{
                if(k === "hardwareRequest" || k === "softwareRequest" || k === "hardwareOther"){
                  if(k === "hardwareRequest"){
                    // Hardware Part
                    return(
                      <div className='hardSoftDiv'>
                        <div style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"hardware"} value={"hardware"} checked={requestField.includes("hardware") ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                          <div>Hardware Request</div>
                        </div>
                        <div className='row staffOtherSDiv'>
                          <label className='col-sm-4'>{field[k].displayText}</label>
                          <div className='col-sm-8'>
                            <SelectInput options={hardwareOption} defaultOption="Select an option" value={field[k].val} disabled={true} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                          </div>
                        </div>
                        {field["hardwareRequest"].val === "Others" ? 
                          <div className='row staffOtherSDiv'>
                            <label className='col-sm-4'>{field["hardwareOther"].displayText}</label>
                            <div className='col-sm-8'>
                                <input type={field["hardwareOther"].dataType} required={field["hardwareOther"].required} className="form-control" name={"hardwareOther"} value={field["hardwareOther"].val} disabled={true} onChange={(t)=>{handleChanges("hardwareOther", t.target.value)}} />
                            </div>
                        </div>
                        :
                        <></>
                        }
                      </div>
                    )
                  }else if(k === "softwareRequest"){
                    // Software Part
                    return(
                      <div className='hardSoftDiv'>
                        <div style={{ display: 'flex'}}>
                          <ManpowerCheckbox type="checkbox" field={"software"} value={"software"} checked={requestField.includes("software") ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                          <div>Software Request</div>
                        </div>
                        <div className='row softwareBox'>
                          {/* Microsoft office checkbox */}
                          <div className='col-md-6' style={{ display: 'flex'}}>
                            <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"mo"} checked={field[k].val["mo"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                            <div>MS Office</div>
                          </div>
                          {/* Antivirus checkbox */}
                          <div className='col-md-6' style={{ display: 'flex'}}>
                            <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"anti"} checked={field[k].val["anti"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                            <div>Antivirus</div>
                          </div>
                          {/* Adobe Reader checkbox */}
                          <div className='col-md-6' style={{ display: 'flex'}}>
                            <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"adobe"} checked={field[k].val["adobe"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                            <div>Adobe Reader</div>
                          </div>
                          {/* Sharepoint Checkbox */}
                          <div className='col-md-6' style={{ display: 'flex'}}>
                            <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"sp"} checked={field[k].val["sp"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                            <div>Sharepoint</div>
                          </div>
                          {/* Teams Checkbox */}
                          <div className='col-md-6' style={{ display: 'flex'}}>
                            <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"team"} checked={field[k].val["team"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                            <div>Teams</div>
                          </div>
                          {/* Outlook Checkbox */}
                          <div className='col-md-6' style={{ display: 'flex'}}>
                            <ManpowerCheckbox type="checkbox" field={"softwareSelect"} value={"outlook"} checked={field[k].val["outlook"] === true ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={true} />
                            <div>Outlook</div>
                          </div>
                          {/* Others text */}
                          <input type="text" required={false} className="form-control" name={"softwareOther"} value={field[k].val["other"]} disabled={true} onChange={(t)=>{handleChanges("softwareSelect", t.target.value)}} placeholder='Other software (Optional) ' />
                          
                        </div>
                      </div>
                    )
                  }
                }
              })}
            </div>

            <div className='row staffOtherSDiv'>
              <label className='col-sm-4'>{field["requestReason"].displayText}</label>
              <div className='col-sm-8'>
                <textarea rows={6} required={field["requestReason"].required} disabled={true} className="form-control" value={field["requestReason"].val} onChange={(t)=>{handleChanges("requestReason", t.target.value)}} />
              </div>
            </div>

            {from === "approval" ? 
              <>
                <div className='row staffOtherSDiv'>
                  <label className='col-sm-4'>{field["remark"].displayText}</label>
                  <div className='col-sm-8'>
                      <input type={field["remark"].dataType} required={field["remark"].required} className="form-control" name={"remark"} value={field["remark"].val} disabled={disabling} onChange={(t)=>{handleChanges("remark", t.target.value)}} />
                  </div>
                </div>
                <div className='row staffOtherSDiv'>
                  <label className='col-sm-4'>{field["budget"].displayText}</label>
                  <div className='col-sm-8'>
                      <input type={field["budget"].dataType} required={field["budget"].required} className="form-control" name={"budget"} value={field["budget"].val} disabled={disabling} onChange={(t)=>{handleChanges("budget", t.target.value)}} />
                  </div>
                </div>
                <div className='row staffOtherSDiv'>
              <label className='col-sm-4'>{field["status"].displayText}</label>
              <div className='col-sm-8'>
                <SelectInput options={approvalOption} defaultOption="Select an option" value={field["status"].val} disabled={disabling} field={"status"} fromApplicant={true} requiredOption={field["status"].required} onChangeInput={handleChanges} />
              </div>
            </div>
              </>
            
            :
              <></>
            }
            
          </div>
          :
            <></>
        }

      {from === "create" ? 
        <WidgetCreateFormBtnGrpC action="submit" /> 
      : from === "approval" ?
        <WidgetCreateFormBtnGrpC handleCurAction={handleEditApproval} editable={editable} permission={{edit:1}} action={action === "editApp"? "edit" : "submit"} /> 
      :
        <WidgetCreateFormBtnGrpC /> 
      } 
      </form>
    </div>
  )
}


//Helpdesk form
function CreateHelpdeskForm({module, from, curNav="Add", field, subjectOption, handleChanges, handleSubmit, userDept, userRole, completeHour, completeMinute}){
  var curNav = ""
  if(from === "create"){
    curNav = "Add"
  }else{
    curNav = "Ticket Information"
  }
  
  return(
    <div>
      <form onSubmit={handleSubmit}>
        {
          module && from === "create" ? 
            <React.Fragment>
              <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
              <br />
            </React.Fragment>
          :
            null
        }
        {from === "create" ? 
          Object.keys(field).map((k)=>{
            return(
              <div className='row staffOtherSDiv'>
                <label className='col-sm-4'>{field[k].displayText}</label>
                <div className='col-sm-8'>
                  {field[k].dataType === "text" ? 
                    <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={field[k].val} disabled={field[k].disable} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                  : field[k].dataType === "SelectOne" ?
                    <SelectInput options={subjectOption} defaultOption="Select an option" value={field[k].val} disabled={field[k].disable} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                  : field[k].dataType === "textbox" ?
                    <textarea rows={6} required={field[k].required} disabled={field[k].disable} className="form-control" value={field[k].val} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                  :
                    <></>
                  }
                </div>
              </div>
            )
          })
          : from === "details" ?
          Object.keys(field).map((k)=>{
            var disablefield = true

            /// use to determine whether if we want to disable the solution, completon time reason and completion time
            if(userDept === "5" || userDept === "Sadmin" || userDept === "Admin" || userRole === "Sadmin" || userRole === "Admin"){
              if(field["status"].val.includes("Acknowledged by")){
                disablefield = false
              }
            }

            if(k !== "helpdeskId" && k !== "rateStatus" && k !== "rateDetail" && k !== "completedDt"){
              if(field["status"].val === "Pending for acknowledgement"){
                if(k !=="solution" && k !== "timeTaken" && k !== "completeReason" && k !== "responseDt" && k !== "completedBy"){


                  return(
                    <div className='row staffOtherSDiv'>
                      <label className='col-sm-4'>{field[k].displayText}</label>
                      <div className='col-sm-8'>
                        {field[k].dataType === "text" ? 
                          <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={field[k].val} disabled={field[k].disable} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                        : field[k].dataType === "SelectOne" ?
                          <SelectInput options={subjectOption} defaultOption="Select an option" value={field[k].val} disabled={field[k].disable} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                        : field[k].dataType === "textbox" ?
                          <textarea rows={6} required={field[k].required} disabled={field[k].disable} className="form-control" value={field[k].val} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                        :
                          <></>
                        }
                      </div>
                    </div>
                  )
                }
              }else{
                if(k === "completeReason" || k === "solution"){
                  return(
                    <div className='row staffOtherSDiv'>
                      <label className='col-sm-4'>{field[k].displayText}</label>
                      <div className='col-sm-8'>
                        <textarea rows={6} required={field[k].required} disabled={disablefield} className="form-control" value={field[k].val} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                      </div>
                    </div>
                  )
                }
                if(k === "timeTaken"){
                  return(
                    <div className='row staffOtherSDiv'>
                      <label className='col-sm-4'>{field[k].displayText}</label>
                      <div className='row col-sm-8'>
                        <div className='row col-sm-6'>
                          {/* Hour Input */}
                          <div className='col-sm-4'>
                            <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={completeHour} disabled={disablefield} onChange={(t)=>{handleChanges(k, t.target.value, "hour")}} />
                          </div>
                          <div className='col-sm-8'>
                            <label>Hour</label>
                          </div>
                        </div>
                        <div className='row col-sm-6'>
                          <div className='col-sm-4'>
                            <input type={field[k].dataType} required={field[k].required} className="form-control" disabled={disablefield} name={k} value={completeMinute} onChange={(t)=>{handleChanges(k, t.target.value, "minute")}} />
                          </div>
                          <div className='col-sm-8'>
                            <label>Minutes</label>
                          </div>
                        </div>
                        {/* Minutes Input */}
                      </div>
                    </div>
                  )
                }else if(k === "completedBy"){
                  if(field["status"].val.includes("Completed by")){
                    return(
                      <div className='row staffOtherSDiv'>
                        <label className='col-sm-4'>{field[k].displayText}</label>
                        <div className='col-sm-8'>
                          <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={field[k].val} disabled={disablefield} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                        </div>
                      </div>
                    )
                  }else{
                    return(
                      <></>
                    )
                  }
                }else{
                  return(
                    <div className='row staffOtherSDiv'>
                      <label className='col-sm-4'>{field[k].displayText}</label>
                      <div className='col-sm-8'>
                        {field[k].dataType === "text" ? 
                          <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={field[k].val} disabled={field[k].disable} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                        : field[k].dataType === "SelectOne" ?
                          <SelectInput options={subjectOption} defaultOption="Select an option" value={field[k].val} disabled={field[k].disable} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                        : field[k].dataType === "textbox" ?
                          <textarea rows={6} required={field[k].required} disabled={field[k].disable} className="form-control" value={field[k].val} onChange={(t)=>{handleChanges(k, t.target.value)}} />
                        :
                          <></>
                        }
                      </div>
                    </div>
                  )
                }
              }
            }
          })
          :
          <></>
        }
        
        {from === "create" || field["status"].val.includes("Acknowledged by") && userDept === "5" ? 
          <WidgetCreateFormBtnGrpC action="submit" />        
        : (userDept === "5" || userDept === "Sadmin" || userDept === "Admin" || userRole === "Sadmin" || userRole === "Admin") && field["status"].val === "Pending for acknowledgement" ?
          <WidgetCreateFormBtnGrpC acknowledge={true} />        
        :
          <WidgetCreateFormBtnGrpC />        
        }
      </form>
    </div>
  )
}

//Rating Bar funcion
function appraisalRatingBar(field, handleChanges, rateName, k, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit){
  if(managerCanEdit){
    return(
      <div style={ratingContainer}>
        <div style={field[k].val === "1"? buttonHoverStyle : isHover === "1" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("1", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "1", rateName)} >1</div>
        <div style={field[k].val === "2"? buttonHoverStyle : isHover === "2" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("2", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "2", rateName)} >2</div>
        <div style={field[k].val === "3"? buttonHoverStyle : isHover === "3" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("3", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "3", rateName)} >3</div>
        <div style={field[k].val === "4"? buttonHoverStyle : isHover === "4" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("4", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "4", rateName)} >4</div>
        <div style={field[k].val === "5"? buttonHoverStyle : isHover === "5" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("5", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "5", rateName)} >5</div>
      </div>
    )
  }
    return(
      <div style={ratingContainer}>
        <div style={field[k].val === "1"? viewButtonHoverStyle : viewButtonStyle}>1</div>
        <div style={field[k].val === "2"? viewButtonHoverStyle : viewButtonStyle}>2</div>
        <div style={field[k].val === "3"? viewButtonHoverStyle : viewButtonStyle}>3</div>
        <div style={field[k].val === "4"? viewButtonHoverStyle : viewButtonStyle}>4</div>
        <div style={field[k].val === "5"? viewButtonHoverStyle : viewButtonStyle}>5</div>
      </div>
    )
  
}

//Staff Appraisal manager and director rating and comment
function managerAndDirectorRate(field, handleChanges, evalOption, rateName, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, permission, managerCanEdit, from){
  return(
    <>
    
      <div className='col-sm-6 staffOtherSDiv'>
        <h6><u>{from === "director" ? "Director" : "Manager"}</u></h6>
        <label>{from === "director" ? "Director Comment" : "Manager Comment"}</label>
        <textarea rows={6} required={field["managerComment"].required} disabled={managerCanEdit ? false : true} className="form-control" onChange={(t)=>handleChanges("managerComment", t.target.value, rateName)} value={field["managerComment"].val} />
        <div className='row'>
          <label className='col-sm-4'>{from === "director" ? "Director Rating" : "Manager Rating"}</label>
          <div className='col-sm-8'>

            {appraisalRatingBar(field, handleChanges, rateName, "managerRating", ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit)}

            {/* <AppSelectInput options={evalOption} defaultOption="Select an option" value={field["managerRating"].val} requiredOption={field["managerRating"].require} rateName={rateName} field={"managerRating"} onChangeInput={handleChanges} /> */}
          </div>
        </div>
      </div>
    </>
  )
}

//Create Staff Appraisal form
function CreateStaffAppraisalForm({module, field, handleChanges, techSkill, evalOption, interSkill, workQuality, workApproach, otherComment, curNav = "Details", ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, permission, handleSubmit, from, empCode}){

  // Control editable input box based on the "FROM" variable which will be pass when using this appraisal form component
  var staffCanEdit = true
  var managerCanEdit = true

  var dueDate = new Date(field["aDueDate"].val)
  dueDate.setHours(23,59,59)
  var todayDate = new Date()
  var notOverdue = dueDate > todayDate

  if(notOverdue){
    if(from === "staff"){
      if(field["aFilled"].val === "Complete" || empCode !== field["aOwner"].val){
        staffCanEdit = false
        managerCanEdit = false
      }else{
        managerCanEdit = false
      }
    }else if( from === "manager"){
      if(field["aStatus"].val === "Pending for Approval" || field["aStatus"].val === "Approved"){
        managerCanEdit = false
        staffCanEdit = false
      }else{
        staffCanEdit = false
      }
    }else if(from === "director"){
      if(field["aStatus"].val === "Approved"){
        managerCanEdit = false
        staffCanEdit = false
      }else{
        staffCanEdit = false
      }
    }else if(from === "supervisor"){
      if(field["aStatus"].val === "Pending for Approval" || field["aStatus"].val === "Approved"){
        managerCanEdit = false
        staffCanEdit = false
      }
    }
  }else{
    staffCanEdit = false
    managerCanEdit = false
  }

  return(
    <div>
      <form onSubmit={handleSubmit}>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      <br />

      {/* General Field */}
      <div className='row staffGeneral'>
      {
        Object.keys(field).map((k)=>{
          if(k === "Name" || k === "aPosition" || k === "dateJoin" || k === "currentPositionYear" || k === "reportManagerName"){
            return(
              <div className='col-sm-6 staffSpace'>
                <div className='row'>
                  <div className="col-sm-6 " htmlFor={k}><b>{field[k].displayText}</b></div>
                  <div className='col-sm-6'>
                    {k === "currentPositionYear"? 
                      <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} disabled={staffCanEdit? false: true} />
                    :
                      <div>{field[k].val}</div>
                    }
                  </div>
                </div>
              </div>
            )
          }
        })
      }
      </div>

      {/* Appraisal Rating table */}
      {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"?
        <>
          <br />
          <hr />
            <h6><u>Rating Score Reference</u></h6>
            {AppraisalRatingTable()}
          <hr />
        </>
      :
        <></>
      }

      {/* Technical Skills */}
      <div className='row staffOtherDiv'>
        <h6>
          <u>Technical Skills</u>
        </h6>
        {/* To show evaluation reference */}
        {from === "staff" ? 
          <>
            <small>(Effectiveness with which the employee applies job knowledge and skill to job assignments)
            <div className='row'>
              <div className='col-sm-2 evalDivLabel'>
                <div className='evalDivInnerLabel'>
                  <b>Evaluation<br/>Reference</b>
                </div>
              </div>
              <div className='col-sm-10 evalDivContent'>
                <div>
                  <ul>
                    <li>1. Job Knowledge</li> <br/>
                    <li>2. Analyses Problems and Provides Solutions</li><br/>
                    <li>3. Employs Tools of the Job Competently</li><br/>
                    <li>4. Organizational & Time Management Skills</li>
                  </ul>
                </div>
              </div>
            </div>
            </small>
          </>
        :
          <></>
        }
        
        {/* End of to show evaluation reference */}

        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff" ? 
          <div className='col-sm-6'>
            {
              Object.keys(techSkill).map((k)=>{
                if(k !== "achievement" && k !== "managerRating" && k !== "managerComment" && k !== "directorRating" && k !== "directorComment"){
                  return(
                    <div className='row staffOtherSDiv'>
                      <div className='col-sm-6'>{techSkill[k].displayText}</div>
                      <div className='col-sm-6'>
                        {appraisalRatingBar(techSkill, handleChanges, "techSkill", k, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit)}
                        
                        {/* <AppSelectInput options={evalOption} defaultOption="Select an option" value={techSkill[k].val} requiredOption={techSkill[k].require} field={k} rateName="techSkill" onChangeInput={handleChanges} /> */}
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        :
            <></>
        }
        <div className='col-sm-6 staffOtherSDiv'>
          <div>{techSkill["achievement"].displayText}</div>
          <textarea rows={6} required={techSkill["achievement"].required} disabled={staffCanEdit ? false:true} className="form-control" value={techSkill["achievement"].val} onChange={(t)=>handleChanges("achievement", t.target.value, "techSkill")} />
        </div>

        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"?
          managerAndDirectorRate(techSkill, handleChanges, evalOption, "techSkill", ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, permission, managerCanEdit, from)
        :
        <></>
        }
       
      </div>

        <br/>
        <br/>
        <br/>
        <hr/>
      
      {/* Quality of Work */}
      <div className='row staffOtherDiv'>
        <h6><u>Quality of Work</u></h6>
        {/* To show evaluation reference */}
        {from === "staff" ? 
          <>
            <small>(Manner in which the employee completes job assignments)
            <div className='row'>
              <div className='col-sm-2 evalDivLabel'>
                <div className='evalDivInnerLabel'>
                  <b>Evaluation<br/>Reference</b>
                </div>
              </div>
              <div className='col-sm-10 evalDivContent'>
                <div className='row'>
                  <ul className='col-sm-6'>
                    <li>1. Accuracy or Precision</li><br/>
                    <li>2. Thoroughness</li><br/>
                    <li>3. Reliability</li><br/>
                    
                  </ul>
                  <ul className='col-sm-6'>
                    <li>4. Responsiveness to requests for help or service</li>
                    <li>5. Safety awareness & consciousness at work place</li>
                  </ul>
                </div>
              </div>
            </div>
            </small>
          </>
        :
          <></>
        }
        {/* End of to show evaluation reference */}
        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"?
          <div className='col-sm-6'>
            {
              Object.keys(workQuality).map((k)=>{
                if(k !== "achievement" && k !== "managerRating" && k !== "managerComment" && k !== "directorRating" && k !== "directorComment"){
                  return(
                    <div className='row staffOtherSDiv'>
                      <div className='col-sm-6'>{workQuality[k].displayText}</div>
                      <div className='col-sm-6'>
                        {appraisalRatingBar(workQuality, handleChanges, "workQuality", k, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit)}
                        {/* <AppSelectInput options={evalOption} defaultOption="Select an option" value={workQuality[k].val} requiredOption={workQuality[k].require} field={k} rateName="workQuality" onChangeInput={handleChanges} /> */}
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        :
        <></>
        }
        <div className='col-sm-6 staffOtherSDiv'>
          <div>{workQuality["achievement"].displayText}</div>
          <textarea rows={6} required={workQuality["achievement"].required} disabled={staffCanEdit ? false:true} className="form-control" value={workQuality["achievement"].val} onChange={(t)=>handleChanges("achievement", t.target.value, "workQuality")} />
        </div>

        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"?
          managerAndDirectorRate(workQuality, handleChanges, evalOption, "workQuality", ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, permission, managerCanEdit, from)
        :
        <></>
        }
      </div>

      <br/>
      <br/>
      <br/>
      <hr/>

      

      {/* Interpersonal Skills */}
      <div className='row staffOtherDiv'>
        <h6><u>Interpersonal Skills</u></h6>
         {/* To show evaluation reference */}
         {from === "staff" ? 
          <>
            <small>(Effectiveness of the employee’s interactions with others and as part of a team)
            <div className='row'>
              <div className='col-sm-2 evalDivLabel'>
                <div className='evalDivInnerLabel'>
                  <b>Evaluation<br/>Reference</b>
                </div>
              </div>
              <div className='col-sm-10 evalDivContent'>
                <div className='row'>
                  <ul className='col-sm-6'>
                    <li>1. With Co-workers</li><br/>
                    <li>2. With Supervisors</li><br/>
                    <li>3. With End users/Clients</li><br/>
                 
                  </ul>
                  <ul className='col-sm-6'>
                    <li>4. Team Participation</li> <br/>
                    <li>5. Team Contributions</li> <br/>
                    <li>6. Commitment to Team Success</li>
                  </ul>
                </div>
              </div>
            </div>
            </small>
          </>
        :
          <></>
        }
        {/* End of to show evaluation reference */}
        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"? 
          <div className='col-sm-6'>
            {
              Object.keys(interSkill).map((k)=>{
                if(k !== "achievement" && k !== "managerRating" && k !== "managerComment" && k !== "directorRating" && k !== "directorComment"){
                  return(
                    <div className='row staffOtherSDiv'>
                      <div className='col-sm-6'>{interSkill[k].displayText}</div>
                      <div className='col-sm-6'>
                        {appraisalRatingBar(interSkill, handleChanges, "interSkill", k, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit)}
                        {/* <AppSelectInput options={evalOption} defaultOption="Select an option" value={interSkill[k].val} requiredOption={interSkill[k].require} field={k} rateName="interSkill" onChangeInput={handleChanges} /> */}
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
          :
          <></>
        }
        <div className='col-sm-6 staffOtherSDiv'>
          <div>{interSkill["achievement"].displayText}</div>
          <textarea rows={6} required={interSkill["achievement"].required} disabled={staffCanEdit ? false:true} className="form-control" value={interSkill["achievement"].val} onChange={(t)=>handleChanges("achievement", t.target.value, "interSkill")} />
        </div>

        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"? 
          managerAndDirectorRate(interSkill, handleChanges, evalOption, "interSkill", ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, permission, managerCanEdit, from)   
        :
          <></>
        }
      </div>

      <br/>
      <br/>
      <br/>
      <hr/>

     

      {/* Approach to Work */}
      <div className='row staffOtherDiv'>
        <h6><u>Approach to Work</u></h6>
        {/* To show evaluation reference */}
        {from === "staff" ? 
          <>
            <small>(Characteristics of the employee that have demonstrated while performing job assignments)
            <div className='row'>
              <div className='col-sm-2 evalDivLabel'>
                <div className='evalDivInnerLabel'>
                  <b>Evaluation<br/>Reference</b>
                </div>
              </div>
              <div className='col-sm-10 evalDivContent'>
                <div className='row'>
                  <ul className='col-sm-6'>
                    <li>1. Actively Seeks Ways to Streamline Processes</li><br/>
                    <li>2. Open to New Ideas and Approaches</li><br/>
                    <li>3. Takes Initiative</li><br/>
                  </ul>
                  <ul className='col-sm-6'>
                    <li>4. Planning and Organization</li><br/>
                    <li>5. Flexible/Adaptable</li><br/>
                    <li>6. Seeks Additional Training and Development</li>
                  </ul>
                </div>
              </div>
            </div>
            </small>
          </>
        :
          <></>
        }
        {/* End of to show evaluation reference */}
        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff"?
          <div className='col-sm-6'>
            {
              Object.keys(workApproach).map((k)=>{
                if(k !== "achievement" && k !== "managerRating" && k !== "managerComment" && k !== "directorRating" && k !== "directorComment"){
                  return(
                    <div className='row staffOtherSDiv'>
                      <div className='col-sm-6'>{workApproach[k].displayText}</div>
                      <div className='col-sm-6'>
                        {appraisalRatingBar(workApproach, handleChanges, "workApproach", k, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit)}
                        {/* <AppSelectInput options={evalOption} defaultOption="Select an option" value={workApproach[k].val} requiredOption={workApproach[k].require} field={k} rateName="workApproach" onChangeInput={handleChanges} /> */}
                      </div>
                    </div>
                  )
                }
              })
            }
          </div>
        
          :
          <></>
        }
        <div className='col-sm-6 staffOtherSDiv'>
          <div>{workApproach["achievement"].displayText}</div>
          <textarea rows={6} required={workApproach["achievement"].required} disabled={staffCanEdit ? false:true} className="form-control" value={workApproach["achievement"].val} onChange={(t)=>handleChanges("achievement", t.target.value, "workApproach")} />
        </div>

        {(permission.viewManagerApproval || permission.viewDirectorApproval || permission.viewOverviewStaff || permission.viewOverviewSupervisor) && from !== "staff" ? 
          managerAndDirectorRate(workApproach, handleChanges, evalOption, "workApproach", ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, permission, managerCanEdit, from)
        :
        <></>
        }
      </div>

      <br/>
      <br/>
      <br/>
      <hr/>

     
      {/* Other Comments */}
      <div className='row staffOtherDiv'>
        <h6><u>Other Comments</u></h6>
          {
            Object.keys(otherComment).map((k)=>{
              if(k === "actionPlan" || k === "employeeComment" || k === "managerComment"){
                if(k === "managerComment"){
                  if((permission.viewManagerApproval || permission.viewDirectorApproval) && from !== "staff"){
                    return(
                      <div className='row staffOtherSDiv'>
                        <div className='col-sm-6'>{otherComment["managerComment"].displayText}</div>
                        <div className='col-sm-6'>
                          <textarea rows={6} required={otherComment["managerComment"].required} disabled={managerCanEdit? false: true} className="form-control" value={otherComment["managerComment"].val} onChange={(t)=>handleChanges("managerComment", t.target.value, "otherComment")} />
                        </div>
                      </div>
                    )
                  }
                }else{
                  return(
                    <div className='row staffOtherSDiv'>
                      <div className='col-sm-6'>{otherComment[k].displayText}</div>
                      <div className='col-sm-6'>
                        <textarea rows={6} required={otherComment[k].required} disabled={staffCanEdit ? false:true} className="form-control" value={otherComment[k].val} onChange={(t)=>handleChanges(k, t.target.value, "otherComment")} />
                      </div>
                    </div>
                  )
                }
              }else if((permission.viewManagerApproval || permission.viewDirectorApproval) && from !== "staff"){
                return(
                  <div className='row staffOtherSDiv'>
                    <div className='col-sm-6'>{otherComment[k].displayText}</div>
                    <div className='col-sm-6'>
                      {appraisalRatingBar(otherComment, handleChanges, "otherComment", k, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle, managerCanEdit)}
                      {/* <AppSelectInput options={evalOption} defaultOption="Select an option" value={otherComment[k].val} requiredOption={otherComment[k].require} field={k} rateName="otherCommment" onChangeInput={handleChanges} /> */}
                    </div>
                  </div>
                )
              }
            })
          }
      </div>
      {notOverdue ? 
        from === "staff" ?
          field["aFilled"].val === "Complete" || empCode !== field["aOwner"].val?
            <WidgetCreateFormBtnGrpC action="" />
            :
            <WidgetCreateFormBtnGrpC action="submit" />
        : from === "manager" || from === "supervisor" || from === "director"?
            field["aStatus"].val === "Pending for Approval" || field["aStatus"].val === "Approved"?
              <WidgetCreateFormBtnGrpC action="" />
            :
              <WidgetCreateFormBtnGrpC action="submit" />
        : 
          <></>
      :
        <WidgetCreateFormBtnGrpC action="" />
      }
      </form>
    </div>
  )
}

//Create login form
function CreateLoginFormTemplate({field, handleChanges}){
  return(
    <div>
      {Object.keys(field).map((k)=>{
        return(
          <div className="form-group" key={k}>
            <label htmlFor={k}>{field[k].displayText}</label>
            <Input type={k === "password" ? "password" : "text"} onChangeInput={(t)=>handleChanges(k, t.target.value)} value={field[k].val} field={k} />
          </div>  
        )
      })}
    </div>
  )
}

//Create Job Application form
function CreateApplicantFormTemplate({genField, eduField, empField, refField, otherField,  secIdx, secList, genderOption, handleGenChanges, handleDynamicRemove, yesAndNoOption, haveOrNotOption}){

  return(
    <div>
      {secIdx === 1? // General List
        Object.keys(genField).map(k =>{
          return(
            <div className="form-group row" key={k}>
              <label className="col-sm-4 " htmlFor={k}>{genField[k].displayText}</label>
              <div className="col-sm-8">
                {k ==="dob"?
                  <Input type="date" onChangeInput={handleGenChanges} value={genField[k].val} field={k} requiredOption={genField[k].require} />
                :k ==="gender"? 
                <SelectInput options={genderOption} defaultOption="Select an option" value={genField[k].val} requiredOption={genField[k].require} field={k} fromApplicant={true} onChangeInput={handleGenChanges} />
                // :k === "photo" ?
                //   <ImportImageForm enabled={true} required={genField[k].require} />
                :
                  <input type={genField[k].dataType} className="form-control" required={genField[k].require} name={k} value={genField[k].val} onChange={(t)=>handleGenChanges(k, t.target.value)} />
                }
              </div>
            </div>
          )
        })
        : secIdx === 2? //Education List
          eduField.map((input, idx)=>{
            return(
              <div className="jobApplcationContentBox" key={idx}>
                {eduField.length > 1 ? 
                  <div className="miniHeader">
                    <h5>Education History {idx+1}</h5>
                    <button type="button" className="btn btn-sm mb-3" onClick={()=>handleDynamicRemove(idx)}>Delete</button>
                  </div>
                :
                  <></>
                }
                {
                  Object.keys(input).map((k)=>{
                    return(
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{input[k].displayText}</label>
                        <div className="col-sm-8">
                          {k === "start" || k === "end" ? 
                            <Input type="date" onChangeInput={handleGenChanges} requiredOption={input[k].require} idx={idx} value={input[k].val} field={k === "start"? "start" : "end"} />
                            :
                            <input type={input[k].dataType} className="form-control" required={input[k].require} name={k} value={input[k].val} onChange={(t)=>handleGenChanges(k,t.target.value,idx)} />
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        : secIdx === 3? //Employment List
          empField.map((input, idx)=>{
            return(
              <div className="jobApplcationContentBox" key={idx}>
                {empField.length > 1 ? 
                  <div className="miniHeader">
                    <h5>Employement History {idx+1}</h5>
                    <button type="button" className="btn btn-sm mb-3" onClick={()=>handleDynamicRemove(idx)}>Delete</button>
                  </div>
                :
                  <></>
                }
                {
                  Object.keys(input).map((k)=>{
                    return(
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{input[k].displayText}</label>
                        <div className="col-sm-8">
                          {k === "empStart" || k === "empEnd" ? 
                            <Input type="date" onChangeInput={handleGenChanges} requiredOption={input[k].require} value={input[k].val} idx={idx} field={k === "empStart" ? "empStart" :"empEnd"} />
                            :
                            <input type={input[k].dataType} className="form-control" name={k} required={input[k].require} value={input[k].val} onChange={(t)=>handleGenChanges(k,t.target.value,idx)} />
                          }
                          </div>
                      </div>
                    )
                  })
                }

              </div>
            )
          })
        : secIdx === 4 ? //Reference Part
        <>
          <div><p><i>* Feel free to skip if don't have any work referer</i></p></div>
          {
            Object.keys(refField).map(k =>{
              return(
                <div className="form-group row" key={k}>
                  <label className="col-sm-4 " htmlFor={k}>{refField[k].displayText}</label>
                  <div className="col-sm-8">
                    <input type={refField[k].dataType} className="form-control" required={refField[k].require} name={k} value={refField[k].val} onChange={(t)=>handleGenChanges(k,t.target.value)} />
                  </div>
                </div>
              )
            })
          }
        </>
        :// Others List
        Object.keys(otherField).map(k =>{
          return(
            <div className="form-group row" key={k}>
              <label className="col-sm-6 " htmlFor={k}>{otherField[k].displayText}</label>
              <div className="col-sm-6">
                {k === "furPlan" || k ==="majorIll" || k ==="constMed" || k === "certInfo"?
                  <SelectInput options={yesAndNoOption} defaultOption="Select an option" value={otherField[k].val} requiredOption={otherField[k].require} field={k} fromApplicant={true} onChangeInput={handleGenChanges} />
                : k === "criminalCharge" || k === "takeDrug" ? 
                  <SelectInput options={haveOrNotOption} defaultOption="Select an option" value={otherField[k].val} requiredOption={otherField[k].require} field={k} fromApplicant={true} onChangeInput={handleGenChanges} />
                :
                  <input type={otherField[k].dataType} className="form-control" name={k} value={otherField[k].val} required={otherField[k].require} onChange={(t)=>handleGenChanges(k, t.target.value)} />
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

function CreateRecruitmentForm({module, field, handleChanges, yesAndNoOption, recommendOption, interviewType, optionGroups, options, handleSubmit, dept, curNav="Add"}){
  return (
    <div>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      <div className="tab-grid">
        <div>
          {module === "Job Interview" ? //Create form for job interview
            <form id="input-form" onSubmit={handleSubmit}>
              {Object.keys(field).map((k)=>{
                if(k !=="interviewId" && k !== "interviewStatus" && k !== "createdBy" && k !== "createdDt" && k !== "applicantId"){
                  return(
                    <div className="form-group row" key={k}>
                      <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                      <div className="col-sm-8">
                        {k ==="interviewDt"?
                          <div className="row">
                            <div className="col-sm-6">
                              <Input type="date" onChangeInput={handleChanges} field={"date"} requiredOption={field[k].required} />
                            </div>
                            <div className="col-sm-6">
                              <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("time", t.target.value)} requiredOption={field[k].required} />
                            </div>
                          </div>
                        : k === "interviewType" ?
                          <SelectInput options={interviewType} defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                        : k === "department" ? 
                          <SelectInput options={dept} defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} onChangeInput={handleChanges} requiredOption={field[k].required} />
                        : k === "reportManager" ?
                          <SelectInputWithGroup
                            field={k}
                            value={field[k].val}
                            options={options}
                            optionGroups={optionGroups}
                            defaultOption="Select an option"
                            onChangeInput={handleChanges}
                            fromApplicant={true}
                            requiredOption={field[k].required}
                          />
                        :
                          <input type={field[k].dataType} required={field[k].required} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                        }
                      </div>
                    </div>
                  )
                }
              })}
              <WidgetCreateFormBtnGrpC action="submit" />
            </form>      
          : // Create form for manpower form
            <form id="input-form" onSubmit={handleSubmit}>
              {Object.keys(field).map((k)=>{
                if(k !=="manpowerId" && k !== "status" && k !== "requestDate" && k !== "requestBy"){
                  return(
                    <div className="form-group row" key={k}>
                      <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                      <div className="col-sm-8">
                        {k ==="dateRequired"?
                          <Input type="date" onChangeInput={handleChanges} field={k} requiredOption={field[k].required} />
                        : k === "projTitleDept"?
                          <SelectInput options={dept} defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} onChangeInput={handleChanges} requiredOption={field[k].required} />
                        // : k === "requestBy" ?
                        //   <SelectInputWithGroup
                        //     field={k}
                        //     value={field[k].val}
                        //     options={options}
                        //     optionGroups={optionGroups}
                        //     defaultOption="Select an option"
                        //     onChangeInput={handleChanges}
                        //     fromApplicant={true}
                        //     requiredOption={field[k].required} 
                        //   />
                        // : k === "requestDate"?
                        //   <Input type="text" disabled={true} field={k} value={getTodayDate()} />
                        : k === "headCountNeeded" ?
                          <SelectInput options={yesAndNoOption} defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} onChangeInput={handleChanges} requiredOption={field[k].required} />
                          : k === "workingHour" ?
                          <div className='row'>
                            <div className='col-md-6'>
                              <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("startTime", t.target.value)} requiredOption={field[k].required} />
                            </div>
                            <div className='col-md-6'>
                              <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("endTime", t.target.value)} requiredOption={field[k].required} />
                            </div>
                          </div>
                          : k === "manpowerType" ?
                            <div className='row'>
                              <div className='col-md-6' style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px'}}>
                                <div>
                                  <ManpowerCheckbox type="checkbox" field={k} value={"Replacement"} checked={field[k].val === "Replacement" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                                </div>
                                <div>
                                  <p>Replacement</p>
                                </div>
                              </div>
                              <div className='col-md-6' style={{ display: 'flex', paddingTop:'10px'}}>
                                <div>
                                  <ManpowerCheckbox type="checkbox" field={k} value={"New Addition"} checked={field[k].val === "New Addition" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} />
                                </div>  
                                <div>
                                  <p>New Addition</p>
                                </div>
                              </div>
                            </div>
                          :
                          <input type={field[k].dataType} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} required={field[k].required} />
                        }
                      </div>
                    </div>
                  )
                }
              })}
              <WidgetCreateFormBtnGrpC action="submit" />
            </form>
          }
        </div>
      </div>
    </div>
  )
}

function RatingTable(){
  return(
    <div>
      <table className='rateTable'>
        <tr>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
        </tr>
        <tr>
          <td>Not Acceptable</td>
          <td>Below Average</td>
          <td>Average</td>
          <td>Good</td>
          <td>Excellent</td>
        </tr>
      </table>
    </div>
  )
}

function AppraisalRatingTable(){
  return(
    <div>
      <table className='rateTable'>
        <tr>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
        </tr>
        <tr>
          <td>Unsatisfactory</td>
          <td>Less than satisfactory</td>
          <td>Satisfactory</td>
          <td>More than satisfactory</td>
          <td>Well ahead of standard</td>
        </tr>
      </table>
    </div>
  )
}


function CreateRecruitmentEditForm({editable, module, yesAndNoOption, options, recommendOption, dept, optionGroups, interviewType, interviewStatus, curAction, handleCurAction, field, handleChanges, permission, filled, curNav="Details", handleSubmit,viewApplicationForm, curDetails, evalOption, ratingContainer, buttonStyle, handleMouseEnter, handleMouseLeave, isHover, buttonHoverStyle, hoverField, viewButtonStyle, viewButtonHoverStyle }){
  
  return(
    <div>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      {
        module === "Job Interview" ?
        permission.view === 1 && permission.edit === 0 && permission.editApproval === 0 && permission.editRecommend === 0 ? //view permission for interview form
          <form id="input-form" onSubmit={handleSubmit}>
          {Object.keys(field).map((k)=>{
            if(k !=="interviewId" && k !== "applicantId"){
              let disableOption = true

              let showOverallRating = false
              let overallRating = 0

              if(field["canApp"].val !== null && field["canAtt"].val !== null && field["canCom"].val !== null && field["canKnow"].val !== null && field["canLea"].val !== null && field["canMat"].val !== null){
                showOverallRating = true

                overallRating = ((parseFloat(field["canApp"].val) + parseFloat(field["canAtt"].val) + parseFloat(field["canCom"].val) + parseFloat(field["canKnow"].val) + parseFloat(field["canLea"].val)+ parseFloat(field["canMat"].val))/30.00)*100
              }

              if(k === "managerRecommend"){
                return(
                  <>
                     <div className="row">
                      <label className="col-sm-4 ">Application Form</label>
                      <div className="col-sm-8 customP">
                        {filled === "true" ?
                          <button type="button" onClick={()=>viewApplicationForm()}>View</button>
                        :                
                          <p>Pending for applicant to fill up</p>
                        }
                      </div>
                    </div>
                    <br /><hr /><br />
                    <h6>Applicant Evaluation</h6><br />
                    {field["managerRecommend"].val === "Recommend" ? 
                        RatingTable()
                      :
                        <></>
                      }
                    <div className="form-group row" key={k}>
                      <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                      <div className="col-sm-8">
                        <SelectInput options={recommendOption} defaultOption="Select an option" value={field[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                      </div>

                    </div>
                  </>
                )
              }else if(k === "interviewStatus"){
                return(
                  <>
                      <br /><hr /><br />
                      <h6>Director Approval</h6><br />
                      <div className="form-group row" key={k}>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className="col-sm-8">
                          {
                            field["hrAction"].val === "Close Interview"?
                            <input type="text" required={field[k].required} disabled={true} className="form-control" name={k} value="Closed" />
                          :
                            <SelectInput options={interviewStatus} defaultOption="Select an option" value={field[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                          }
                          </div>
                      </div>
                      <br /><br />
                    </>
                )
              }else if(k === "strongPoint" || k === "apparentWeakness" || k === "generalComments" || k === "canCom" || k === "canApp" || k === "canAtt" || k === "canMat" || k === "canLea" || k === "canKnow"){
                if(field["managerRecommend"].val === "Recommend" || field["managerRecommend"].val === "KIV"){
                  if(k === "strongPoint" || k === "apparentWeakness" || k === "generalComments"){
                    return(
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                        <div className="col-sm-8">
                          <textarea rows={4} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                        </div>
                      </div>
                    )
                  }else{
                    return(
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                        <div className="col-sm-8">
                            <div style={ratingContainer}>
                              <div style={field[k].val === "1"? viewButtonHoverStyle : viewButtonStyle}>1</div>
                              <div style={field[k].val === "2"? viewButtonHoverStyle : viewButtonStyle}>2</div>
                              <div style={field[k].val === "3"? viewButtonHoverStyle : viewButtonStyle}>3</div>
                              <div style={field[k].val === "4"? viewButtonHoverStyle : viewButtonStyle}>4</div>
                              <div style={field[k].val === "5"? viewButtonHoverStyle : viewButtonStyle}>5</div>
                            </div>
                        </div>
                        {k === "canKnow" ? 
                          showOverallRating ?
                          <>
                            <label className="col-sm-4 " htmlFor={k}>Overall Rating</label>
                            <label style={{fontSize:'20px'}} className="col-sm-8 " htmlFor={k}>{overallRating.toFixed(2)} %</label>
                          </>
                          :
                          <></>
                          :
                          <></>
                          }
                      </div>
                    )
                  }
                }else if(field["managerRecommend"].val === "Not Recommend"){
                  if(k !== "generalComments"){
                    return <></>
                  }else{
                    return(
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                        <div className="col-sm-8">
                          <textarea rows={4} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                        </div>
                      </div>
                    )
                  }
                }
              }else if(k === "hrAction" || k === "hrDescription"){
                if(curDetails.Department === '3'){
                  if(k === "hrAction"){
                    return(
                      <>
                        <br /><hr /><br />
                        <h6>Interview Management</h6><br />
                        <div className='row'>
                        <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className='col-md-6' style={{ display: 'flex', paddingTop:'10px'}}>
                            <div>
                              <ManpowerCheckbox type="checkbox" field={k} value={"Close Interview"} checked={field[k].val === "Close Interview" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={disableOption} />
                            </div>  
                            <div>
                              <p>Close Interview</p>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }else{
                    return(
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                        <div className="col-sm-8">
                          <textarea rows={4} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                        </div>
                      </div>
                    )
                  }
                }else{
                  return <></>
                }
              }else{
                return(
                  <div className="form-group row" key={k}>
                    <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                    <div className="col-sm-8">
                      {
                        field[k].dataType === "date"?
                        <div className="row">
                            <div className="col-sm-6">
                              <Input type="date" onChangeInput={handleChanges} field={"date"} disabled={disableOption} requiredOption={field[k].required} value={field[k].val} />
                            </div>
                            <div className="col-sm-6">
                              <Input type="time" onChangeInput={handleChanges} field={"time"} requiredOption={field[k].required} disabled={disableOption} value={field[k].val.slice(11, -3)} />
                            </div>
                          </div>
                        : field[k].dataType === "selectOne"?
                          <SelectInput options={
                            k === "department" ? 
                              dept 
                            : k ==="interviewType"?
                              interviewType
                            :
                              interviewStatus
                          } defaultOption="Select an option" value={field[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                        : field[k].dataType === "selectGroup"?
                          <SelectInputWithGroup
                            field={k}
                            value={field[k].val}
                            options={options}
                            optionGroups={optionGroups}
                            defaultOption="Select an option"
                            onChangeInput={handleChanges}
                            fromApplicant={true}
                            requiredOption={field[k].required}
                            disabled={disableOption}
                          />
                          :field[k].dataType === "text" ?
                          <input type={field[k].dataType} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          :field[k].dataType === "textarea" ?
                          <textarea rows={4} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          :
                          <>Loading...</>
                      }
                    </div>
                  </div>
                )
              }
            }
          })}
          <WidgetCreateFormBtnGrpC editable={false} handleCurAction={handleCurAction} action={curAction} permission={permission} />
        </form>
          : //edit permission for interview form
          <form id="input-form" onSubmit={handleSubmit}>
            {Object.keys(field).map((k, idx)=>{
              if(k !=="interviewId" && k !== "applicantId"){
                let disableOption = field[k].disable
                
                let managerEdit = false
                let showOverallRating = false
                let overallRating = 0

                if(curAction === "submit" && permission.editRecommend && curDetails.Name === field["reportManager"].val){
                  managerEdit = true
                }

                if(curAction !== "submit"  || permission.edit === 0){
                  disableOption = true
                }

                if(field["canApp"].val !== null && field["canAtt"].val !== null && field["canCom"].val !== null && field["canKnow"].val !== null && field["canLea"].val !== null && field["canMat"].val !== null){
                  showOverallRating = true

                  overallRating = ((parseFloat(field["canApp"].val) + parseFloat(field["canAtt"].val) + parseFloat(field["canCom"].val) + parseFloat(field["canKnow"].val) + parseFloat(field["canLea"].val)+ parseFloat(field["canMat"].val))/30.00)*100
                }

                
                if(k === "managerRecommend"){
                  return(
                    <>
                       <div className="row">
                        <label className="col-sm-4 ">Application Form</label>
                        <div className="col-sm-8 customP">
                          {filled === "true" ?
                            <button type="button" onClick={()=>viewApplicationForm()}>View</button>
                          :                
                            <p>Pending for applicant to fill up</p>
                          }
                        </div>
                      </div>
                      <br /><hr /><br />
                      <h6>Applicant Evaluation</h6><br />
                      {field["managerRecommend"].val === "Recommend" ? 
                        RatingTable()
                      :
                        <></>
                      }
                      <div className="form-group row" key={k}>
                        <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                        <div className="col-sm-8">
                          <SelectInput options={recommendOption} defaultOption="Select an option" value={field[k].val} disabled={managerEdit ? false : true} field={k} fromApplicant={true} requiredOption={false} onChangeInput={handleChanges} />
                        </div>

                      </div>
                    </>
                  )
                }else if(k === "interviewStatus"){
                  return(
                    <>
                      <br /><hr /><br />
                      <h6>Director Approval</h6><br />
                      <div className="form-group row" key={k}>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className="col-sm-8">
                          {
                            field["hrAction"].val === "Close Interview"?
                            <input type="text" required={field[k].required} disabled={true} className="form-control" name={k} value="Closed" />
                          :
                            <SelectInput options={interviewStatus} defaultOption="Select an option" value={field[k].val} disabled={curAction === "submit" ? permission.editApproval ? false : true : true} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                          }
                          </div>
                      </div>
                      <br /><br />
                    </>
                  )
                }else if(k === "strongPoint" || k === "apparentWeakness" || k === "generalComments" || k === "canCom" || k === "canApp" || k === "canRes" || k === "canAtt" || k === "canIni" || k === "canMat" || k === "canLea" || k === "canKnow"){
                  if(field["managerRecommend"].val === "Recommend" || field["managerRecommend"].val === "KIV"){
                    if(k === "strongPoint" || k === "apparentWeakness" || k === "generalComments"){
                      return(
                        <div className="form-group row" key={k}>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className="col-sm-8">
                            <textarea rows={4} required={false} disabled={managerEdit ? false : true} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          </div>
                        </div>
                      )
                    }else{
                      return(
                        <div className="form-group row" key={k}>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className="col-sm-8">
                          {managerEdit ? 
                                <div style={ratingContainer}>
                                  <div style={field[k].val === "1"? buttonHoverStyle : isHover === "1" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("1", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "1")} >1</div>
                                  <div style={field[k].val === "2"? buttonHoverStyle : isHover === "2" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("2", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "2")} >2</div>
                                  <div style={field[k].val === "3"? buttonHoverStyle : isHover === "3" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("3", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "3")} >3</div>
                                  <div style={field[k].val === "4"? buttonHoverStyle : isHover === "4" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("4", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "4")} >4</div>
                                  <div style={field[k].val === "5"? buttonHoverStyle : isHover === "5" ? hoverField === k ? buttonHoverStyle : buttonStyle : buttonStyle} onMouseEnter={()=>handleMouseEnter("5", k)} onMouseLeave={handleMouseLeave} onClick={()=>handleChanges(k, "5")} >5</div>
                                </div>
                              :
                                <div style={ratingContainer}>
                                  <div style={field[k].val === "1"? viewButtonHoverStyle : viewButtonStyle}>1</div>
                                  <div style={field[k].val === "2"? viewButtonHoverStyle : viewButtonStyle}>2</div>
                                  <div style={field[k].val === "3"? viewButtonHoverStyle : viewButtonStyle}>3</div>
                                  <div style={field[k].val === "4"? viewButtonHoverStyle : viewButtonStyle}>4</div>
                                  <div style={field[k].val === "5"? viewButtonHoverStyle : viewButtonStyle}>5</div>
                                </div>
                              }
                          </div>
                          {k === "canKnow" ? 
                          showOverallRating ?
                          <>
                            <label className="col-sm-4 " htmlFor={k}>Overall Rating</label>
                            <label style={{fontSize:'20px'}} className="col-sm-8 " htmlFor={k}>{overallRating.toFixed(2)} %</label>
                          </>
                          :
                          <></>
                          :
                          <></>
                          }
                        </div>
                      )
                    }
                  }else if(field["managerRecommend"].val === "Not Recommend"){
                    if(k !== "generalComments"){
                      return <></>
                    }else{
                      return(
                        <div className="form-group row" key={k}>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className="col-sm-8">
                            <textarea rows={4} required={field[k].required} disabled={managerEdit ? false : true} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          </div>
                        </div>
                      )
                    }
                  }
                }else if(k === "hrAction" || k === "hrDescription"){
                  if(curDetails.Department === '3'){
                    if(k === "hrAction"){
                      return(
                        <>
                          <br /><hr /><br />
                          <h6>Interview Management</h6><br />
                          <div className='row'>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                            <div className='col-md-6' style={{ display: 'flex', paddingTop:'10px'}}>
                              <div>
                                <ManpowerCheckbox type="checkbox" field={k} value={"Close Interview"} checked={field[k].val === "Close Interview" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={disableOption} />
                              </div>  
                              <div>
                                <p>Close Interview</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }else{
                      return(
                        <div className="form-group row" key={k}>
                          <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                          <div className="col-sm-8">
                            <textarea rows={4} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          </div>
                        </div>
                      )
                    }
                  }else{
                    return <></>
                  }
                }else{
                  return(
                    <div className="form-group row" key={k}>
                      <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                      <div className="col-sm-8">
                        {
                          field[k].dataType === "date"?
                          <div className="row">
                              <div className="col-sm-6">
                                <Input type="date" onChangeInput={handleChanges} field={"date"} disabled={disableOption} requiredOption={field[k].required} value={field[k].val} />
                              </div>
                              <div className="col-sm-6">
                                <Input type="time" onChangeInput={(t)=>handleChanges("time", t.target.value)} field={"time"} requiredOption={field[k].required} disabled={disableOption} value={field[k].val.slice(11, -3)} />
                              </div>
                            </div>
                          : field[k].dataType === "selectOne"?
                            <SelectInput options={
                              k === "department" ? 
                                dept 
                              : k ==="interviewType"?
                                interviewType
                              :
                                interviewStatus
                            } defaultOption="Select an option" value={field[k].val} disabled={disableOption} field={k} fromApplicant={true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                          : field[k].dataType === "selectGroup"?
                            <SelectInputWithGroup
                              field={k}
                              value={field[k].val}
                              options={options}
                              optionGroups={optionGroups}
                              defaultOption="Select an option"
                              onChangeInput={handleChanges}
                              fromApplicant={true}
                              requiredOption={field[k].required}
                              disabled={disableOption}
                            />
                            :field[k].dataType === "text" ?
                            <input type={field[k].dataType} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                            :field[k].dataType === "textarea" ?
                            <textarea rows={4} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                            :
                            <>Loading...</>
                        }
                      </div>
                    </div>
                  )
                }
              }
            })}
            <WidgetCreateFormBtnGrpC editable={editable} handleCurAction={handleCurAction} action={curAction} permission={permission} />
          </form>  
        : //Manpower View only
            permission.view === 1 && permission.edit === 0 && permission.editApproval === 0 ?
            <form id="input-form" onSubmit={handleSubmit}>
              {Object.keys(field).map((k)=>{
                let disableOption = true
                if(k !=="manpowerId"){
                  return(
                    <div className="form-group row" key={k}>
                      <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                      <div className="col-sm-8">
                        {
                          field[k].dataType === "date"?
                            <Input type="date" onChangeInput={handleChanges} disabled={disableOption} field={k} requiredOption={field[k].required} value={field[k].val} />
                          : field[k].dataType === "selectOne"?
                            <SelectInput options={
                              k === "projTitleDept" ? 
                                dept 
                              : k === "status"?
                                interviewStatus
                              :
                                yesAndNoOption
                            } defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} disabled={disableOption} requiredOption={field[k].required} onChangeInput={handleChanges} />
                          : field[k].dataType === "selectGroup"?
                            <SelectInputWithGroup
                              field={k}
                              value={field[k].val}
                              options={options}
                              optionGroups={optionGroups}
                              defaultOption="Select an option"
                              onChangeInput={handleChanges}
                              fromApplicant={true}
                              requiredOption={field[k].required}
                              disabled={disableOption}
                            />
                          :field[k].dataType === "text"?
                          <input type={field[k].dataType} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          :field[k].dataType === "checkbox"?
                            <div className='row'>
                              <div className='col-md-6' style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px'}}>
                                <div>
                                  <ManpowerCheckbox type="checkbox" field={k} value={"Replacement"} checked={field[k].val === "Replacement" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={disableOption} />
                                </div>
                                <div>
                                  <p>Replacement</p>
                                </div>
                              </div>
                              <div className='col-md-6' style={{ display: 'flex', paddingTop:'10px'}}>
                                <div>
                                  <ManpowerCheckbox type="checkbox" field={k} value={"New Addition"} checked={field[k].val === "New Addition" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={disableOption} />
                                </div>  
                                <div>
                                  <p>New Addition</p>
                                </div>
                              </div>
                            </div>
                          :field[k].dataType === "time"?
                            <div className='row'>
                              <div className='col-md-6'>
                                <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("startTime", t.target.value)} requiredOption={field[k].required} disabled={disableOption}  value={field[k].val.split(",")[0]} />
                              </div>
                              <div className='col-md-6'>
                                <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("endTime", t.target.value)} requiredOption={field[k].required} disabled={disableOption}  value={field[k].val.split(",")[1]} />
                              </div>
                            </div>
                          :
                          <>Loading...</>
                        }
                      </div>
                    </div>
                  )
                }
              })}
              <WidgetCreateFormBtnGrpC editable={false} handleCurAction={handleCurAction} action={curAction} permission={permission} />
            </form> 
          : // Edit permission for manpower form 
          <form id="input-form" onSubmit={handleSubmit}>
              {Object.keys(field).map((k)=>{
                if(k !=="manpowerId"){
                  let disableOption = field[k].disable

                  if(curAction !== "submit" || permission.edit === 0){
                    disableOption = true
                  }
                  return(
                    <div className="form-group row" key={k}>
                      <label className="col-sm-4 " htmlFor={k}>{field[k].displayText}</label>
                      <div className="col-sm-8">
                        {
                          field[k].dataType === "date"?
                            <Input type="date" onChangeInput={handleChanges} disabled={disableOption} field={k} requiredOption={field[k].required} value={field[k].val} />
                          : field[k].dataType === "selectOne"?
                            k === "status" ? 
                            <SelectInput options={interviewStatus} defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} disabled={curAction === "submit" ? permission.editApproval ? false : true : true} requiredOption={field[k].required} onChangeInput={handleChanges} />
                            :
                            <SelectInput options={
                              k === "projTitleDept" ? 
                                dept 
                              :
                                yesAndNoOption
                            } defaultOption="Select an option" value={field[k].val} field={k} fromApplicant={true} disabled={disableOption} requiredOption={field[k].required} onChangeInput={handleChanges} />
                          : field[k].dataType === "selectGroup"?
                            <SelectInputWithGroup
                              field={k}
                              value={field[k].val}
                              options={options}
                              optionGroups={optionGroups}
                              defaultOption="Select an option"
                              onChangeInput={handleChanges}
                              fromApplicant={true}
                              requiredOption={field[k].required}
                              disabled={disableOption}
                            />
                          :field[k].dataType === "text"?
                          <input type={field[k].dataType} required={field[k].required} disabled={disableOption} className="form-control" name={k} value={field[k].val} onChange={(t)=>handleChanges(k, t.target.value)} />
                          :field[k].dataType === "checkbox"?
                            <div className='row'>
                              <div className='col-md-6' style={{ display: 'flex', justifyContent: 'center', paddingTop:'10px'}}>
                                <div>
                                  <ManpowerCheckbox type="checkbox" field={k} value={"Replacement"} checked={field[k].val === "Replacement" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={disableOption} />
                                </div>
                                <div>
                                  <p>Replacement</p>
                                </div>
                              </div>
                              <div className='col-md-6' style={{ display: 'flex', paddingTop:'10px'}}>
                                <div>
                                  <ManpowerCheckbox type="checkbox" field={k} value={"New Addition"} checked={field[k].val === "New Addition" ? true : false} onChangeInput={handleChanges} requiredOption={field[k].required} disabled={disableOption} />
                                </div>  
                                <div>
                                  <p>New Addition</p>
                                </div>
                              </div>
                            </div>
                          :field[k].dataType === "time"?
                            <div className='row'>
                              <div className='col-md-6'>
                                <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("startTime", t.target.value)} requiredOption={field[k].required} disabled={disableOption}  value={field[k].val.split(",")[0]} />
                              </div>
                              <div className='col-md-6'>
                                <Input type="time" field={"time"} onChangeInput={(t)=>handleChanges("endTime", t.target.value)} requiredOption={field[k].required} disabled={disableOption}  value={field[k].val.split(",")[1]} />
                              </div>
                            </div>
                          :
                          <>Loading...</>
                        }
                      </div>
                    </div>
                  )
                }
              })}
              <WidgetCreateFormBtnGrpC editable={editable} handleCurAction={handleCurAction} action={curAction} permission={permission} />
            </form> 
      }
    </div>
  )
}

function CreateAppraisalSetup({module, curNav = "Setup", handleSubmit, updatedRecord}){
  return(
    <div>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      {
        <>
          <ExcelDownloadBtn module={module} />
        </>
      }
      <form id="csv-form" onSubmit={handleSubmit}>
        <ImportFileForm text="Upload File" />
      </form>
      <hr />
      {
        updatedRecord ?
        <>
          <br />
          <h6>The following staff appraisal have been created. </h6>
          <br />
          <div className='apDiv'>
            <table className='aTable'>
              <tr>
                <th>No</th>
                <th>Employee Code</th>
                <th>Name</th>
                <th>Email</th>
                <th>Appraisal Year</th>
              </tr>
              {updatedRecord.map((k,idx)=>{
                return(
                  <tr>
                    <td>{idx+1}</td>
                    <td>{k["EmployeeCode"]}</td>
                    <td>{k["Name"]}</td>
                    <td>{k["Email"]}</td>
                    <td>{k["aYear"]}</td>
                  </tr>
                )
              })}
            </table>
          </div>
        </>
        :
        <></>
      }
    </div>
  )
}


function CreateFormTemplate({ module, disabled = false, handleSubmit,handleEmployeeUpdate, fieldItems, importCsv = true, curNav = "Add", editAccess }) {
  return (
    <div>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      <div className="tab-grid">
        <div>
          {
            module == 'Application' || module == 'Employee' ?  <ExcelDownloadBtn module={module} /> : null
          }

          {
            importCsv && !disabled && module != 'HomeLeave' &&
              <form id="csv-form" onSubmit={handleSubmit}>
                <ImportFileForm text="Create in batch by importing excel file" />
              </form>
          }
          {
            importCsv && !disabled && module != 'HomeLeave' &&
              <form id="csv-form-update" onSubmit={handleEmployeeUpdate}>
                <ImportFileForm text="Update Employee List" />
              </form>
          }
          <form id="input-form" onSubmit={handleSubmit}>
            {fieldItems}
            <WidgetCreateFormBtnGrp editAccess={disabled ? false : editAccess} />
          </form>
        </div>
      </div>
    </div>
  )
}

function CreateFormTemplateUserAccount({ module, handleSubmit, fieldItems, mods }) {
  return (
    <div>
      <WidgetBreadCrumbHeader prevNav={module} curNav="Add" prevLink={"#"} curLink={"#"} />
      <br />
      <div className="tab-grid">
        <div>
         <ExcelDownloadBtn module={module} />
          <form id="csv-form" onSubmit={handleSubmit}>
            <ImportFileForm text="Create in batch by importing excel file" />
          </form>
          <form id="input-form" onSubmit={handleSubmit}>
            <div className="form-grid mb-3">
              {fieldItems}
            </div>
            {mods}
            <WidgetCreateFormBtnGrp />
          </form>
        </div>
      </div>
    </div>
  )
}

function CreateFormTemplateSupport({ module, handleSubmit, fieldItems, importCsv = true, curNav = "Add", editAccess, linkPath }) {
  return (
    <div>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      <div className="tab-grid">
        <div>
          {
            importCsv &&
              <form id="csv-form" onSubmit={handleSubmit}>
                <ImportFileForm text="Create in batch by importing excel file" />
              </form>
          }
          <form id="input-form" onSubmit={handleSubmit}>
            {fieldItems}
            <div className="form-button-grid">
                {
                  editAccess ?
                    <>
                        <button type="submit" className="btn btn-primary mt-3">Reply</button>
                        <Link className="btn btn-info mt-3" to={linkPath} >Close</Link>
                        {/* <button onClick={handleSubmit} name="close" type="submit" className="btn btn-info mt-3">Close</button> */}
                    </>
                  : null
                }
                <WidgetBackBtn />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

function EditFormTemplate({ handleSubmit, disabled, setEditMode, fieldItems, editAccess }) {
  const handleSubmitNull = (e) => {
    e.preventDefault()
  }

  return (
    <div>
      <form id="input-form" className="form-grid" onSubmit={editAccess ? handleSubmit : handleSubmitNull} disabled={disabled}>
        {fieldItems} 
        <WidgetEditFormBtnGrp disabled={disabled} setEditMode={setEditMode} editAccess={editAccess} />
      </form>
    </div>
  )
}

function EditFormTemplateTraining({ handleSubmit, disabled, setEditMode, fieldItems, setRenew, editAccess ,show,courseApplyStatus,invoiceFile,courseApplyPermission,dataList,handleChangeInput,projectCode}) {
  const handleSubmitNull = (e) => {
    e.preventDefault()
  }

  return (
    <div>
      {/* <form id="input-form" className="form-grid" onSubmit={editAccess ? handleSubmit : handleSubmitNull} disabled={disabled}>
        {fieldItems} 
        <WidgetTrainingFormBtnGrp disabled={disabled} setEditMode={setEditMode} setRenew={setRenew} editAccess={editAccess} />
      </form> */}
       <form id="input-form" onSubmit={editAccess ? handleSubmit : handleSubmitNull} disabled={disabled}>
            <div class="form-grid">{fieldItems}</div><br/>
            <div>
              {
                invoiceFile && courseApplyPermission? <h4 class="text-warning">Invoice file already attached!  </h4>: null
              } 
            </div><br/>
            <div class="row col-md-6">
              {
                courseApplyStatus === true && courseApplyPermission ? 
                  <ImportImageForm text={invoiceFile? "Update Invoice PDF (File must be PDF and cannot exceed more than 100KB)" : "Upload Invoice PDF (File must be PDF and cannot exceed more than 100KB)"} acceptedTypes={".jpg, .jpeg, .png, .pdf, .pdx"} enabled={!disabled}  required={invoiceFile ?false:true }/>
                : null
              }
            </div>

            <br />
         
            <div calss="col-md-6 form-grid">
              {
                courseApplyStatus === true ?
                <div class="form-group row grid-wide">
                  <div class="col-sm-1">
                    <label htmlFor={'remark'} >{'Remark'}</label>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group">
                          <Input field={'projectCode'} type={'text'} requiredOption={true} dataList={dataList} onChangeInput={handleChangeInput} value={projectCode} disabled={disabled}/>
                    </div>
                    
                  </div>
                </div>
                :null
              }
              
            </div>
          
        <WidgetTrainingFormBtnGrp disabled={disabled} setEditMode={setEditMode} setRenew={setRenew} editAccess={editAccess} />
      </form>
    </div>
  )
}

function EditFormTemplateUserAccount({ handleSubmit, disabled, setEditMode, fieldItems, mods, editAccess }) {
  return (
    <div>
      <form id="input-form" onSubmit={handleSubmit}>
        <div className="form-grid mb-3">
          {fieldItems}
        </div>
        {mods}
        <WidgetEditFormBtnGrp disabled={disabled} setEditMode={setEditMode} editAccess={editAccess} />
      </form>
    </div>
  )
}


function EditFormTemplateHomeLeaveTraining({ handleSubmit, disabled, setEditMode, fieldItems, editAccess,dataList,handleChangeInput}) {

  const handleSubmitNull = (e) => {
   
    e.preventDefault()
  }

  return (
    <div>
      <form id="input-form" onSubmit={editAccess ? handleSubmit : handleSubmitNull} disabled={disabled}>
        
          <div class="form-grid">{fieldItems}</div><br/>
          <br />
         
          
        <WidgetEditFormBtnGrp disabled={disabled} setEditMode={setEditMode}  editAccess={editAccess} />
      </form>
    </div>
  )
}

function CreateFormTemplateHazard({ module,moduleType, checkboxOption, disabled=false, handleSubmit, fieldItems, curNav = "Add", editAccess ,handleChangeInput,handleChangeActivity,activityData,handleChangeProcess,processData,handleChangeConsequence,consequenceData,handleChangeObservation,observationData,inputData,handleChangeSIP,inputSIP,setEditMode}) {

  const activities_result = []; const work_process = []; const consequences = [];const observations = [];

  checkboxOption.forEach((item, index) => {   
      if(index == 0){
        item.forEach((activity_item, activity_index) => {
          activities_result.push(
            <li>
            
              <input type="checkbox" id={`activity_${activity_index + 1}`}  name={`activity_${activity_index + 1}`} onChange={() => handleChangeActivity(activityData[activity_index].id)}  value={activityData[activity_index].checked} checked={activityData[activity_index].checked}   disabled={disabled}/>
              <label for={`activity_${activity_index + 1}`}><img src= {`/uploads/hazard/${activity_item[1]}`}   /></label>
              <center> {activity_item[0]} </center>
            </li>
          );
        });
      }
      if(index == 1){
        item.forEach((process_item, process_index) => {
          work_process.push(
            <li>
                <input type="checkbox" id={`process_${process_index + 1}`} name={processData[process_index].name} onChange={() => handleChangeProcess(processData[process_index].id)}  value={processData[process_index].checked} checked={processData[process_index].checked}  disabled={disabled}/>
                <label for={`process_${process_index + 1}`}><img src= {`/uploads/hazard/${process_item[1]}`}   /></label>
                <center> {process_item[0]} </center>
            </li>
          ); 
        });
      }
      if(index == 2){
        item.forEach((consequence_item, consequence_index) => {
          consequences.push(
              <li>
                <input type="checkbox"  id={`consequence_${consequence_index + 1}`} name={consequenceData[consequence_index].name} onChange={() => handleChangeConsequence(consequenceData[consequence_index].id)}  value={consequenceData[consequence_index].checked} checked={consequenceData[consequence_index].checked} disabled={disabled}  />
                <label for={`consequence_${consequence_index + 1}`}><img src= {`/uploads/hazard/${consequence_item[1]}`}   /></label>
                <center> {consequence_item[0]} </center>
            </li>
          );
        });
      }
      if(index == 3){
        item.forEach((observation_item, observation_index) => {
          observations.push(
              <div class="col">
                  <input type="checkbox" id={`observation_${observation_index + 1}`} name={observationData[observation_index].name} onChange={() => handleChangeObservation(observationData[observation_index].id)}  value={observationData[observation_index].checked} checked={observationData[observation_index].checked} disabled={disabled}  />
                  <label for={`observation_${observation_index + 1}`}> &nbsp;&nbsp; {observation_item[0]} </label>
              </div>
          );
        });
      }
  });

  return (
    <div>
      {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      
      <div className="tab-grid">
        <div>
          <form id="input-form" onSubmit={handleSubmit}  method="post" enctype="multipart/form-data" disabled={disabled}>
            {fieldItems}
            <div class="row">
              <div class="col-md-3">
                 <label> Image : </label>
              </div>

              <div class="col-md-9">
                <input  id="formFile" className="form-control" type="file" name="file-upload" onChange={handleChangeInput}  disabled={disabled} />
              </div>
            </div>
            <hr/>
            
            {/* Activities */}
            <div class="card shadow mt-4" id= { disabled ? "hazard-no-drop" : "hazard" } >
              <div class="card-header card-background">
                  <b> 1. Activities </b>  
              </div>
              <div class="card-body" >
              
                  What is the type of work the team is working on? 
                  <ul>                   
                    {activities_result}
                  </ul>
              </div>
            </div>
            
            {/* Description */}
            <div class="card shadow mt-4" >
              <div class="card-header card-background">
                  <b>2. Description of safe / unsafe observation</b>  
              </div>
              <div class="card-body" >
                  <textarea  id="description"  name="description" rows='4' className='textwrapper form-control' onChange={handleChangeInput} value={inputData['description']} disabled={disabled}></textarea>
              </div>
            </div>

            {/* Think/Throught */}
            <div class="card shadow mt-4" id= { disabled ? "hazard-no-drop" : "hazard" } >
              <div class="card-header card-background">
                 <b> 3. Think / Throught the work process </b> 
              </div>
              <div class="card-body" >
                    What can go wrong? Think of all possible consequences.
                    <ul>
                      {work_process}
                    </ul>
              </div>
            </div>
 
            {/* SIP */}
            <div class="card shadow mt-4" id="sip">
              <div class="card-header card-background">
                <b> 4. System in place </b>  
              </div>
              <div class="card-body" >
                  {/* group1 */}
                  <div class="row">
                    <div class="col-md-9">
                      Documentation <br/>
                      <span class="sip-font">
                      (Approved PTW permit / Risk Assessment / Work Method of Statement and required attachment, displayed on site etc)
                      </span> 

                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp1_sip1" name="group1" value="NA" onClick={handleChangeSIP} checked={inputSIP['group1'] === 'NA'} disabled={disabled} />
                        <label for="gp1_sip1" > &nbsp; N/A</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp1_sip2"  name="group1" value="YES" onClick={handleChangeSIP} checked={inputSIP['group1'] === 'YES'} disabled={disabled}/> 
                        <label for="gp1_sip2" > &nbsp; YES</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp1_sip3" name="group1" value="NO" onClick={handleChangeSIP} checked={inputSIP['group1'] === 'NO'} disabled={disabled}/>
                        <label for="gp1_sip3" > &nbsp; NO</label>
                    </div>
                  </div>

                  {/* group2 */}
                  <div class="row mt-3">
                    <div class="col-md-9">
                      Site Preparation
                      <br/>
                      <span class="sip-font">
                        (Barricade, signboards, access way, work platform, cables elevated, fire extinguisher etc)
                      </span>
                    </div>
                    
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp2_sip1" name="group2" value="NA" onClick={handleChangeSIP} checked={inputSIP['group2'] === 'NA'} disabled={disabled}/>
                        <label for="gp2_sip1" > &nbsp; N/A</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp2_sip2"  name="group2" value="YES" onClick={handleChangeSIP} checked={inputSIP['group2'] === 'YES'} disabled={disabled}/> 
                        <label for="gp2_sip2" > &nbsp; YES</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp2_sip3" name="group2" value="NO" onClick={handleChangeSIP} checked={inputSIP['group2'] === 'NO'} disabled={disabled}/>
                        <label for="gp2_sip3" > &nbsp; NO</label>
                    </div>
                  </div>

                  {/* group3 */}
                  <div class="row mt-3">
                    <div class="col-md-9">
                       Tools and Equipment
                      <br/>
                      <span class="sip-font">
                       (Valid certificate, inspected, updated checklist, in good condition, suitable for the job etc)
                      </span>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp3_sip1" name="group3" value="NA" onClick={handleChangeSIP} checked={inputSIP['group3'] === 'NA'} disabled={disabled}/>
                        <label for="gp3_sip1" > &nbsp; N/A</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp3_sip2"  name="group3" value="YES" onClick={handleChangeSIP} checked={inputSIP['group3'] === 'YES'} disabled={disabled}/> 
                        <label for="gp3_sip2" > &nbsp; YES</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp3_sip3" name="group3" value="NO" onClick={handleChangeSIP} checked={inputSIP['group3'] === 'NO'} disabled={disabled}/>
                        <label for="gp3_sip3" > &nbsp; NO</label>
                    </div>
                  </div>

                  {/* group4 */}
                  <div class="row mt-3">
                    <div class="col-md-9">
                       Manpower Deployment
                      <br/>
                      <span class="sip-font">
                        (Trained, conducted briefing, understand work process, precautions taken, suitable PPE, duties assigned etc)
                      </span>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp4_sip1" name="group4" value="NA" onClick={handleChangeSIP} checked={inputSIP['group4'] === 'NA'} disabled={disabled}/>
                        <label for="gp4_sip1" > &nbsp; N/A</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"   id="gp4_sip2"  name="group4" value="YES" onClick={handleChangeSIP} checked={inputSIP['group4'] === 'YES'} disabled={disabled}/> 
                        <label for="gp4_sip2" > &nbsp; YES</label>
                    </div>
                    <div class="col-md-1 p-0" >
                        <input type="radio"  id="gp4_sip3" name="group4" value="NO" onClick={handleChangeSIP} checked={inputSIP['group4'] === 'NO'} disabled={disabled}/>
                        <label for="gp4_sip3" >&nbsp; NO</label>
                    </div>
                  </div>

                  {/* group5 */}
                  <div class="row mt-3">
                    <div class="col-md-9">
                       Others
                      <br/>
                      <span class="sip-font">
                        (No incompatible work activities, no overcrowding, suitable ambient temperature, proper work area etc)
                      </span>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio" id="gp5_sip1" name="group5" value="NA" onClick={handleChangeSIP} checked={inputSIP['group5'] === 'NA'} disabled={disabled}/>
                        <label for="gp5_sip1" > &nbsp; N/A</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio" id="gp5_sip2"  name="group5" value="YES" onClick={handleChangeSIP} checked={inputSIP['group5'] === 'YES'} disabled={disabled}/> 
                        <label for="gp5_sip2" > &nbsp; YES</label>
                    </div>
                    <div class="col-md-1 p-0">
                        <input type="radio"  id="gp5_sip3" name="group5" value="NO" onClick={handleChangeSIP} checked={inputSIP['group5'] === 'NO'} disabled={disabled}/>
                        <label for="gp5_sip3" > &nbsp; NO</label>
                    </div>
                  </div>
                 
              </div>
            </div>

            {/* Consequences */}
            <div class="card shadow mt-4" id= { disabled ? "hazard-no-drop" : "hazard" } >
              <div class="card-header card-background">
                  <b> 5. Consequences </b>  
              </div>
              <div class="card-body" >
                  What can go wrong? Think of all possible consequences.
                  <ul>
                    {consequences}
                  </ul>
                  <hr/>
                  Others (please specify) : 
                  <div class="row col-md-12 mt-2 specify-padding">
                    <textarea  className='form-control'  rows='3' name="other"  onChange={handleChangeInput} value={inputData['other']}  disabled={disabled}></textarea>

                  </div>
              </div>
              
            </div>

            {/* Observation */}
            <div class="card shadow mt-4" id="observation">
              <div class="card-header card-background">
                  <b> 6. Observation  </b>  
              </div>
              <div class="card-body row row-cols-5" >
                  {observations}
              </div>
            </div>

             {/* Action Taken */}
             <div class="card shadow mt-4" >
              <div class="card-header card-background">
                  <b> 7. Action Taken </b>  
              </div>
              <div class="card-body" >
                  <textarea  id="action" name="action" rows='4' className='textwrapper form-control' onChange={handleChangeInput} value={inputData['action']} disabled={disabled}></textarea>
              </div>
            </div>

            {
             moduleType == 'create' ? <WidgetCreateFormBtnGrp disabled={disabled} /> : 
                                     <WidgetEditFormBtnGrp disabled={disabled} setEditMode={setEditMode} editAccess={editAccess} />
            }
            
            
            {/* <WidgetCreateFormBtnGrp editAccess={disabled ? false : editAccess} /> */}
          </form>
        </div>
      </div>
    </div>
  )
}

function CreateFormTemplateInvoice({ module,disabled = false,handleSubmit, handleChangeInput, fieldItems, editAccess,curNav = "Add"}) {
 
  return (
    <div>
       {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      <div className="tab-grid">
        <div>
           <form id="input-form" onSubmit={handleSubmit}>
            <ImportInvoiceForm text="Upload invoice file" handleChangeInput={handleChangeInput} />
             {fieldItems}
            <WidgetCreateFormBtnGrp editAccess={disabled ? false : editAccess} />
          </form>
        </div>
      </div>
    </div>
  )
}

function CreateFormTemplateDo({ module,disabled = false,handleSubmit, handleChangeInput, fieldItems, editAccess,curNav = "Add"}) {
 
  return (
    <div>
       {
        module ? 
          <React.Fragment>
            <WidgetBreadCrumbHeader prevNav={module} curNav={curNav} prevLink={"#"} curLink={"#"} />
            <br />
          </React.Fragment>
        :
          null
      }
      <div className="tab-grid">
        <div>
           <form id="input-form" onSubmit={handleSubmit}>
            <ImportDoForm text="Upload DO file" handleChangeInput={handleChangeInput} />
             {fieldItems}
            <WidgetCreateFormBtnGrp editAccess={disabled ? false : editAccess} />
          </form>
        </div>
      </div>
    </div>
  )
}

function EditFormTemplateInvoiceApplication({ handleSubmit, disabled, setEditMode, fieldItems, editAccess,dataList,handleChangeInput}) {

  const handleSubmitNull = (e) => {
   
    e.preventDefault()
  }

  return (
    <div>
      <form id="input-form" onSubmit={editAccess ? handleSubmit : handleSubmitNull} disabled={disabled}>
        
          <div class="form-grid">{fieldItems}</div><br/>
          <br />
         
          
        <WidgetEditFormBtnGrp disabled={disabled} setEditMode={setEditMode}  editAccess={editAccess} />
      </form>
    </div>
  )
}

function EditFormTemplateDoApplication({ handleSubmit, disabled, setEditMode, fieldItems, editAccess,dataList,handleChangeInput}) {

  const handleSubmitNull = (e) => {
   
    e.preventDefault()
  }

  return (
    <div>
      <form id="input-form" onSubmit={editAccess ? handleSubmit : handleSubmitNull} disabled={disabled}>
        
          <div class="form-grid">{fieldItems}</div><br/>
          <br />
         
          
        <WidgetEditFormBtnGrp disabled={disabled} setEditMode={setEditMode}  editAccess={editAccess} />
      </form>
    </div>
  )
}


export { CreateFixedAssetForm, CreateRequisitionForm, CreateHelpdeskForm, CreateAppraisalSetup, CreateStaffAppraisalForm, CreateApplicantFormTemplate, CreateLoginFormTemplate, CreateRecruitmentForm, CreateRecruitmentEditForm, CreateFormTemplate, CreateFormTemplateUserAccount, CreateFormTemplateSupport, EditFormTemplate, EditFormTemplateTraining, EditFormTemplateUserAccount, EditFormTemplateHomeLeaveTraining, CreateFormTemplateHazard,CreateFormTemplateInvoice, CreateFormTemplateDo, EditFormTemplateInvoiceApplication , EditFormTemplateDoApplication}
